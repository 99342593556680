import axios from 'axios';
import { backendBaseUrl } from '../constants/app.constants.js';

const axiosInstance = axios.create({
  baseURL: backendBaseUrl, // Replace with your API base URL
  withCredentials: true,
});

// Request interceptor
axiosInstance.interceptors.request.use(
  config => {
    return config;
  },
  error => {
    return Promise.reject(error);
  }
);

export default axiosInstance;
