import { makeStyles } from '@material-ui/core/styles';
const useStyles = makeStyles(theme => ({
  userName: {
    marginLeft: '5px',
  },
  userNameContainer: {
    display: 'flex',
    alignItems: 'center',
    cursor: 'pointer',
  },
  userIcon: {
    marginTop: '6px',
  },
  logo: {
    color: 'white',
    textDecoration: 'none',
    width: '160px',
    cursor: 'pointer',
  },
  appBar: {
    [theme.breakpoints.up('md')]: {
      display: 'none',
    },
    maxHeight: '64px',
    minHeight: '64px',
    backgroundColor: '#161616',
    zIndex: 1,
  },
  space: {
    flex: '1',
  },
  menuButton: {
    color: '#fff',
    marginRight: '0.5rem',
    cursor: 'pointer',
  },
  headerLoginText: {
    fontWeight: '500',
    marginLeft: '0.5rem',
  },
  languageContainer: {
    [theme.breakpoints.down('xs')]: {
      display: 'none',
    },
    cursor: 'pointer',
    display: 'flex',
    margin: '30px',
    alignItems: 'center',
  },
  selectedLanguage: {
    marginLeft: '0.7rem',
    fontWeight: '400',
  },
}));

export { useStyles };
