import { appActions } from './app.actions';
import { userConstants } from '../constants/user.constants';
import axiosInstance from '../services/axiosInstance';

export const userActions = {
  logout,
  setUserLoading,
  setUser,
  getUser,
  updateSubscription,
};

function logout() {
  document.cookie = 'x-auth-cookie=;expires=Thu, 01 Jan 1970 00:00:00 GMT; domain=.sorisori.ai';
  document.cookie = 'x-auth-refresh=;expires=Thu, 01 Jan 1970 00:00:00 GMT; domain=.sorisori.ai';

  return { type: userConstants.LOGOUT };
}

function setUserLoading(status) {
  return {
    type: userConstants.SET_LOADING,
    user: { loading: status },
  };
}

function updateSubscription(subscription) {
  return {
    type: userConstants.UPDATE_SUBSCRIPTION,
    subscription,
  };
}

function setUser(user) {
  return {
    type: userConstants.SET_USER,
    user,
    loading: false,
  };
}

function getUser(dispatch) {
  dispatch(this.setUserLoading(true));

  axiosInstance
    .get('/auth/login/success')
    .then(response => {
      if (response.status === 200 && response.data.user) {
        axiosInstance.defaults.headers.common['userId'] = response.data.user.id;
        dispatch(this.setUser(response.data.user));
      } else {
        throw new Error('authentication has been failed!');
      }
    })
    .catch(err => {
      document.cookie = 'x-auth-cookie=;expires=Thu, 01 Jan 1970 00:00:00 GMT; domain=.sorisori.ai';
      document.cookie = 'x-auth-refresh=;expires=Thu, 01 Jan 1970 00:00:00 GMT; domain=.sorisori.ai';
      dispatch(this.setUserLoading(false));
      console.log(err);
    })
    .finally(() => dispatch(appActions.setInitialized(true)));
}
