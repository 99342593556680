import React, { useEffect, useState } from 'react';
import Modal from '@material-ui/core/Modal';
import { useTranslation } from 'react-i18next';
import { useStyles } from './EditLabelModal.styles.js';
import CircularProgress from '@mui/material/CircularProgress';
import OutlinedInput from '@mui/material/OutlinedInput';

export default function EditLabelModal(props) {
  const { t } = useTranslation();
  const classes = useStyles();

  const [label, setLabel] = useState('');
  const [fontStyle, setFontStyle] = useState('TheJamsil');

  const { open, loading } = props;

  useEffect(() => setLabel(props.label), [props.label]);

  useEffect(() => {
    const defaultLanguage = localStorage.getItem('selectedLanguage') || navigator.language.split('-')[0];
    const defaultFont = defaultLanguage === 'ja' ? 'MPlus1' : 'TheJamsil';
    setFontStyle(defaultFont);
  }, []);

  const onClick = res => {
    if (res) props.onEdit(label);
    else props.onEditModalClose();
  };
  return (
    <Modal className={fontStyle} open={!!open} onClose={() => props.onEditModalClose()}>
      <div className={classes.container}>
        {!loading && (
          <>
            <div className={classes.label}>
              <OutlinedInput onChange={e => setLabel(e.target.value)} value={label} className={classes.labelInput} />
            </div>
            <div className={classes.buttonContainer}>
              <div className={classes.yesButton} onClick={() => onClick(true)}>
                {t('historyTab.changeButton')}
              </div>
              <div className={classes.noButton} onClick={() => onClick(false)}>
                {t('historyTab.cancelButton')}
              </div>
            </div>
          </>
        )}
        {loading && <CircularProgress className={classes.loading} />}
      </div>
    </Modal>
  );
}
