import { appConstants } from '../constants/app.constants';

export const appActions = {
  openLoginModal,
  closeLoginModal,
  openMessageModal,
  closeMessageModal,
  openLanguageModal,
  closeLanguageModal,
  openMessageModalV2,
  closeMessageModalV2,
  setInitialized,
  selectCommunityItem,
  clearSelectedCommunityItem,
  openCustomMessageModal,
  closeCustomMessageModal,
};

function openLoginModal() {
  const currentPath = window.location.pathname;
  sessionStorage.setItem('preLoginRoute', currentPath);
  return {
    type: appConstants.OPEN_LOGIN_MODAL,
    payload: { preLoginRoute: currentPath },
  };
}

function closeLoginModal() {
  return { type: appConstants.CLOSE_LOGIN_MODAL };
}

function openMessageModal(message, reloadOnClose, showRefreshButton) {
  return {
    type: appConstants.OPEN_MESSAGE_MODAL,
    payload: { message, reloadOnClose, showRefreshButton },
  };
}

function closeMessageModal() {
  return { type: appConstants.CLOSE_MESSAGE_MODAL };
}

function openMessageModalV2({ message, showProfileButton }) {
  return {
    type: appConstants.OPEN_MESSAGE_MODAL_V2,
    payload: { message, showProfileButton },
  };
}

function closeMessageModalV2() {
  return { type: appConstants.CLOSE_MESSAGE_MODAL_V2 };
}

function openLanguageModal() {
  return { type: appConstants.OPEN_LANGUAGE_MODAL };
}

function closeLanguageModal() {
  return { type: appConstants.CLOSE_LANGUAGE_MODAL };
}

function selectCommunityItem(selectedModel) {
  return {
    type: appConstants.SELECT_COMMUNITY_ITEM,
    payload: {
      selectedModel: selectedModel,
    },
  };
}

function clearSelectedCommunityItem() {
  return {
    type: appConstants.CLEAR_SELECTED_COMMUNITY_ITEM,
  };
}

function setInitialized(initialized) {
  return { type: appConstants.SET_INITIALIZED, payload: { initialized } };
}

function openCustomMessageModal(usage, customMessage) {
  return {
    type: appConstants.OPEN_CUSTOM_MESSAGE_MODAL,
    payload: { usage, customMessage },
  };
}

function closeCustomMessageModal() {
  return { type: appConstants.CLOSE_CUSTOM_MESSAGE_MODAL };
}
