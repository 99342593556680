import { makeStyles } from '@material-ui/core/styles';
const useStyles = makeStyles(theme => ({
  pageTitle: {
    [theme.breakpoints.down('sm')]: {
      marginTop: '1.5rem',
    },
    paddingLeft: '0.2rem',
    fontSize: '2.5rem',
    color: '#fff',
  },
  separater: {
    borderBottomWidth: '1px',
    borderBottomColor: '#A4A4A4',
    borderBottomStyle: 'solid',
    margin: '1.5rem 0',
  },
  stepHeader: {
    [theme.breakpoints.down('xs')]: {
      fontSize: '1.4rem',
    },
    fontSize: '1.6rem',
    marginBottom: '0.5rem',
    color: '#fff',
    fontWeight: '400',
    display: 'flex',
    alignItems: 'center',
  },
  stepSubHeader: {
    [theme.breakpoints.down('xs')]: {
      fontSize: '0.83rem',
    },
    paddingBottom: '0.3rem',
    fontSize: '1rem',
    color: '#fff',
    fontWeight: '300',
  },
  inputSourceButtonContainer: {
    marginTop: '1rem',
    marginBottom: '1rem',
    backgroundColor: '#818181',
    padding: '0.2rem',
  },
  inputSourceButton: {
    [theme.breakpoints.down('xs')]: {
      padding: '0.2rem 1rem!important',
    },
    fontWeight: '400',
    backgroundColor: '#818181!important',
    color: '#000!important',
    padding: '0.2rem 2rem!important',
    border: 'none!important',
  },
  selected: {
    backgroundColor: '#F0F0F0!important',
    borderRadius: '0.2rem!important',
  },
  dragAndDropContainer: {
    marginTop: '0.5rem',
  },
  dragAndDrop: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    padding: '1rem 0',
    justifyContent: 'center',
  },
  dragAndDropText: {
    fontWeight: '300',
    fontSize: '1rem',
    color: '#8A8A8A',
  },
  dragAndDropButton: {
    marginTop: '0.8rem',
    fontWeight: '300',
    fontSize: '1rem',
    color: '#1D1D1D',
    backgroundColor: '#F0F0F0',
    borderRadius: '0.4rem',
    padding: '0.5rem 1.5rem',
  },
  durationText: {
    [theme.breakpoints.down('xs')]: {
      fontSize: '0.85rem',
    },
    color: '#fff',
    fontWeight: '300',
    paddingTop: '0',
    paddingBottom: '0',
  },
  formLabelCol: {
    display: 'flex',
    alignItems: 'stretch',
    justifyContent: 'stretch',
    fontSize: '25px',
    fontWeight: 'bold',
    marginBottom: '20px',
  },
  dragAndDropHeader: {
    marginLeft: '0.5rem',
    marginTop: '1rem',
    color: '#fff',
    fontWeight: '300',
  },
  dragAndDropTextContainer: {
    height: '100%',
    display: 'flex',
    flex: 1,
    alignItems: 'center',
  },
  acceptedFileOuterContainer: {
    display: 'flex',
  },
  acceptedFileInnerContainer: {
    marginLeft: '1rem',
    marginTop: '1.5rem',
    color: '#fff',
    fontWeight: '300',
    display: 'flex',
    alignItems: 'center',
    border: '0.1rem solid',
    padding: '0.5rem',
    borderRadius: '0.5rem',
  },
  acceptedFileDelete: {
    marginLeft: '0.5rem',
    width: '1.3rem',
    marginTop: '0.1rem',
    cursor: 'pointer',
  },
  buttonContainer: {
    display: 'flex',
    alignItems: 'center',
  },
  buttonRemainingText: {
    [theme.breakpoints.down('xs')]: {
      fontSize: '0.9rem',
    },
    marginLeft: '1rem',
    color: '#fff',
  },
  button: {
    [theme.breakpoints.down('xs')]: {
      fontSize: '1rem',
      padding: '0.7rem 1rem',
    },
    backgroundColor: '#CAFF73',
    padding: '0.7rem 2.5rem',
    fontSize: '1.1rem',
    borderRadius: '0.2rem',
    cursor: 'pointer',
  },
  disabledButton: {
    backgroundColor: '#CAFF73',
  },
  buttonGrid: {
    display: 'flex',
    justifyContent: 'start',
    marginBottom: '5rem',
  },
  laodingContainer: {
    display: 'flex',
    alignItems: 'center',
  },
  laodingText: {
    marginLeft: '1rem',
    color: '#fff',
  },
  laodingAnimation: {
    width: '25px',
    height: '25px',
  },
  tooltipImg: {
    marginLeft: '0.5rem',
    width: '1.3rem',
  },
  tooltipContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'stretch',
    [theme.breakpoints.down('xs')]: {
      padding: '1rem 0',
    },
    padding: '1rem',
    color: '#161616',
  },
  tooltipHeader: {
    alignSelf: 'center',
    fontSize: '1.5rem',
    fontWeight: '500',
    marginBottom: '1rem',
  },
  tooltipSubeaderCenter: {
    alignSelf: 'center',
    marginTop: '1.3rem',
    marginBottom: '0.8rem',
    fontSize: '1.1rem',
    fontWeight: '500',
  },
  tooltipTextContainer: {
    display: 'flex',
  },
  tooltipText: {
    [theme.breakpoints.down('xs')]: {
      fontSize: '0.9rem',
      lineHeight: '1rem',
    },
    fontSize: '1.1rem',
    fontWeight: '300',
    margin: '0.3rem 0',
    lineHeight: '1.3rem',
  },
  tooltipTextNumber: {
    marginRight: '0.2rem',
  },
  tooltipTextBullet: {
    marginLeft: '0.5rem',
    marginRight: '0.8rem',
  },
  dragAndDropList: {
    paddingTop: '1rem!important',
    paddingBottom: '0!important',
  },
  listItem: {
    paddingTop: 0,
    paddingBottom: 0,
    height: '40px',
  },
  listItemAvatar: {
    minWidth: '40px',
  },
  avatar: {
    width: '25px',
    height: '25px',
  },
  audioIcon: {
    width: '20px',
    height: '20px',
  },
  listItemText: {
    color: '#fff',
  },
  noMargin: {
    margin: '0',
  },
  labelInput: {
    maxWidth: '20rem',
    width: '100%',
    backgroundColor: '#fff',
  },
  mdxContainer: {
    paddingTop: '1rem!important',
  },
  radioButtonContainer: {
    [theme.breakpoints.down('sm')]: {
      height: '4rem',
    },
    display: 'flex!important',
    flexDirection: 'row!important',
    alignItems: 'center',
    justifyContent: 'start',
    height: '1rem',
  },
  radioButtonLabel: {
    [theme.breakpoints.down('xs')]: {
      fontSize: '0.85rem!important',
    },
    marginRight: '1rem',
    fontWeight: '300',
    fontSize: '1.1rem!important',
    color: '#fff',
    display: 'flex',
    alignItems: 'center',
  },
}));

export { useStyles };
