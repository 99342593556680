import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
  customMessageContainer: {
    [theme.breakpoints.down('xs')]: {
      padding: '1rem 1rem',
    },
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    display: 'flex',
    alignItems: 'start',
    flexDirection: 'column',
    backgroundColor: '#fff',
    padding: '2rem 3rem',
    wordBreak: 'keep-all!important',
    borderRadius: '0.2rem',
    '&:active': {
      outline: 'none',
    },
    '&:focus': {
      outline: 'none',
    },
  },
  customMessage: {
    [theme.breakpoints.down('xs')]: {
      fontSize: '0.75rem',
    },
    fontSize: '0.9rem',
    marginBottom: '0.2rem',
  },
}));

export { useStyles };
