import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
  container: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    height: 300,
    borderRadius: '8px',
    display: 'flex',
    //alignItems: "stretch",
    flexDirection: 'column',
    backgroundColor: '#1E1E1E;',
    padding: '2rem 2rem',
    wordBreak: 'keep-all!important',
    '&:active': {
      outline: 'none',
    },
    '&:focus': {
      outline: 'none',
    },
    [theme.breakpoints.down(440)]: {
      width: 330,
    },
  },
  modalTitle: {
    [theme.breakpoints.down('sm')]: {
      marginTop: '1.5rem',
    },
    paddingLeft: '1rem',
    fontSize: '2.5rem',
    color: '#fff',
    alignItems: 'center',
    display: 'flex',
  },
  separater: {
    borderBottomWidth: '1px',
    borderBottomColor: '#A4A4A4',
    borderBottomStyle: 'solid',
    margin: '1.5rem 0',
  },
  sliderContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    spacing: '1',
    marginTop: '0.5rem',
    marginBottom: '3.5rem',
  },
  sliderIconContainer: {
    [theme.breakpoints.down('xs')]: {
      marginRight: '0',
    },
    marginRight: '1.3rem',
    // width: '9rem',
    display: 'flex',
    alignItems: 'center',
  },
  sliderIcon: {
    width: '4rem',
  },
  sliderName: {
    alignItems: 'flex-start',
    lineHeight: '1.3rem',
    marginRight: '1.2rem',
    marginLeft: '-0.2rem',
    //marginBotton: '40rem',
    color: '#fff',
    flexDirection: 'row',
  },
  slider: {
    '& .MuiSlider-valueLabel': {
      fontSize: '0.8rem',
      //fontWeight: 'normal',
      top: -20,
      backgroundColor: 'unset',
      color: theme.palette.text.primary,
      '&::before': {
        display: 'none',
      },
      '& *': {
        background: 'transparent',
        color: '#fff',
      },
    },
    marginTop: '0.8rem',
  },
  tooltipContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'stretch',
    padding: '1rem',
    color: '#161616',
  },
  tooltipImg: {
    marginRight: '1.3rem',
    width: '1.3rem',
  },
  tooltipText: {
    fontSize: '1.1rem',
    fontWeight: '300',
    margin: '0.3rem 0',
    lineHeight: '1.3rem',
  },
  sliderStart: {
    marginTop: '-0.5rem',
    fontSize: '0.7rem',
    fontWeight: '300',
    //marginLeft: '3.5rem',
    lineHeight: '1.3rem',
    color: '#bdbdbd',
  },
  sliderEnd: {
    marginTop: '-0.5rem',
    marginRight: '-0.3rem',
    fontSize: '0.7rem',
    fontWeight: '300',
    lineHeight: '1.3rem',
    color: '#bdbdbd',
  },
}));

export { useStyles };
