import _ from 'lodash';
import axiosInstance from './axiosInstance';
import { v4 as uuidv4 } from 'uuid';
import { getCurrency, zipFiles, zipModelFiles } from '../utils/page.utils';

export const fetchInferencesChunk = async (chunk, page, query, signal, voiceId) => {
  const response = await axiosInstance.get('/inferences/chunk', {
    params: { chunk, page, query, voiceId },
    signal,
  });
  return response?.data?.data;
};

export const fetchTTSInferencesChunk = async (chunk, page, query, signal) => {
  const response = await axiosInstance.get('/ttsInferences/chunk', {
    params: { chunk, page, query },
    signal,
  });
  return response?.data?.data;
};

export const fetchInferences = async () => {
  const response = await axiosInstance.get('/inferences');
  return response;
};

export const fetchTypeTags = async query => {
  const response = await axiosInstance.get('/tags/get-type-tags', {
    params: { query: query },
  });
  return response?.data?.data;
};

export const fetchAllTags = async () => {
  const response = await axiosInstance.get('/tags');
  return response?.data?.data;
};

export const fetchPagedCommunityItems = async (page, pageSize, query, tags, sortingMethod, userId) => {
  const params = {
    page: page,
    pageSize: pageSize,
    query,
    tags,
    sortingMethod,
    userId,
  };
  const response = await axiosInstance.get('/communityItemsPublic', {
    params: params,
  });
  return response?.data?.data;
};

export const fetchPagedTTSCommunityItems = async (page, pageSize, query, tags, sortingMethod, userId) => {
  const params = {
    page: page,
    pageSize: pageSize,
    query,
    tags,
    sortingMethod,
    userId,
  };
  const response = await axiosInstance.get('/ttsCommunityPublic/communityItems', {
    params: params,
  });
  return response?.data?.data;
};

export const fetchPagedUserCommunityItems = async (page, pageSize, query, tags, sortingMethod, userId) => {
  const params = {
    page: page,
    pageSize: pageSize,
    query,
    tags,
    sortingMethod,
    userId,
  };
  const response = await axiosInstance.get('/communityItems/get-user-community-items-paged', {
    params: params,
  });
  return response?.data?.data;
};

export const fetchPagedCommunityItemsLiked = async (page, pageSize, query, tags, sortingMethod, userId) => {
  const params = {
    page: page,
    pageSize: pageSize,
    query,
    tags,
    sortingMethod,
    userId,
  };
  const response = await axiosInstance.get('/communityItems/community-items-liked-paged', {
    params: params,
  });
  return response?.data?.data;
};

export const fetchCommunityItems = async () => {
  const response = await axiosInstance.get('/communityItemsPublic');
  return response?.data?.data;
};

export const fetchTaggedCommunityItems = async tags => {
  const response = await axiosInstance.get('/communityItemsPublic/tagged-community-items', { params: { tags } });
  return response?.data?.data;
};
export const fetchTaggedLikedCommunityItems = async tags => {
  const response = await axiosInstance.get('/communityItems/tagged-liked-community-items', { params: { tags } });
  return response?.data?.data;
};

export const fetchCommunityItemsLiked = async () => {
  const response = await axiosInstance.get('/communityItems/community-items-liked');
  return response?.data?.data?.communityItems;
};

export const fetchUserCommunityItems = async () => {
  const response = await axiosInstance.get('/communityItems/get-user-community-items');
  return response?.data?.data;
};

export const fetchTrainingsChunk = async (chunk, page, query, complete, signal) => {
  const response = await axiosInstance.get('/trainings/chunk', {
    params: { chunk, page, query, complete },
    signal,
  });
  return response?.data?.data;
};

export const fetchTTSTrainingsChunk = async (chunk, page, query, complete, signal) => {
  const response = await axiosInstance.get('/ttsTrainings/chunk', {
    params: { chunk, page, query, complete },
    signal,
  });
  return response?.data?.data;
};

export const fetchTrainings = async () => {
  const response = await axiosInstance.get('/trainings');
  return response?.data?.data;
};

export const fetchVocalExtractsChunk = async (chunk, page, query, signal) => {
  const response = await axiosInstance.get('/vocalExtracts/chunk', {
    params: { chunk, page, query },
    signal,
  });
  return response?.data?.data;
};

export const fetchVocalExtracts = async () => {
  const response = await axiosInstance.get('/vocalExtracts');
  return response?.data?.data;
};

export const fetchSubscriptionInfo = async () => {
  const response = await axiosInstance.get('/subscriptions/get-info');
  return response?.data?.data;
};

export const triggerStripeSession = async priceModel => {
  const response = await axiosInstance.post('/stripe/create-checkout-session', {
    priceModel,
  });
  window.location.href = response.data.url;
};

export const triggerStripeCustomerPortalSession = async () => {
  const response = await axiosInstance.post('/stripe/create-customer-portal-session');
  window.location.href = response.data.url;
};

export const triggerOnetimeStripePortalSession = async type => {
  const response = await axiosInstance.post('/stripe/create-onetime-portal-session', {
    type,
  });
  window.location.href = response.data.url;
};

export const updateTrainingById = async (id, update) => {
  const response = await axiosInstance.patch(`/trainings/${id}`, update);
  return response?.data?.data;
};

export const updateTTSTrainingById = async (id, update) => {
  const response = await axiosInstance.patch(`/ttsTrainings/${id}`, update);
  return response?.data?.data;
};

export const updateCommunityItemById = async (id, update, image) => {
  const formData = new FormData();
  formData.append('data', JSON.stringify(update));
  if (image) {
    formData.append('file', image);
  }
  const response = await axiosInstance.patch(`/communityItems/${id}`, formData);
  return response?.data?.data;
};

export const deleteCommunityItemById = async id => {
  const response = await axiosInstance.delete(`/communityItems/${id}`);
  return response?.data?.data;
};

export const postInferences = async data => {
  const response = await axiosInstance.post('/inferences', data);
  return response?.data?.data;
};

export const postTrainings = async data => {
  const response = await axiosInstance.post('/trainings', data);
  return response?.data?.data;
};

export const updateInferenceById = async (id, update) => {
  const response = await axiosInstance.patch(`/inferences/${id}`, update);
  return response?.data?.data;
};

export const updateTTSInferenceById = async (id, update) => {
  const response = await axiosInstance.patch(`/ttsInferences/${id}`, update);
  return response?.data?.data;
};

export const checkInferenceCompletionById = async inferenceId => {
  const response = await axiosInstance.get(`/inferences/check-completion/${inferenceId}`);
  return response?.data?.data;
};

export const checkInferenceCompletion = async (userId, incompleteList) => {
  const response = await axiosInstance.get('/inferences/check-completion');
  return response?.data?.data;
};

export const checkTTSInferenceCompletion = async (userId, incompleteList) => {
  const response = await axiosInstance.get('/ttsInferences/check-completion');
  return response?.data?.data;
};

export const checkTrainingCompletion = async () => {
  const response = await axiosInstance.get('/trainings/check-completion');
  return response?.data?.data;
};

export const checkTTSTrainingCompletion = async () => {
  const response = await axiosInstance.get('/ttsTrainings/check-completion');
  return response?.data?.data;
};

export const getInferenceBlob = async (inferenceId, fileName) => {
  const response = await axiosInstance.post(
    '/inferences/get-inference-blob',
    {
      inferenceId,
      fileName,
    },
    { responseType: 'blob' }
  );
  return response?.data;
};

export const getTTSInferenceBlob = async id => {
  const response = await axiosInstance.post(
    '/ttsInferences/get-inference-blob',
    {
      id,
    },
    { responseType: 'blob' }
  );
  return response?.data;
};

export const getInferenceSignedUrl = async (inferenceId, fileName, downloadName) => {
  const response = await axiosInstance.post('/inferences/get-signed-url', {
    inferenceId,
    fileName,
    downloadName,
  });
  return response?.data?.data;
};

export const getCommunitySampleSignedUrl = async (communityItemId, downloadName) => {
  const response = await axiosInstance.post('/communityItemsPublic/get-sample-signed-url', {
    communityItemId,
    downloadName,
  });
  return response?.data?.data;
};

// TODO: delete after checking new method works
export const uploadModelMethodOne_DEPRECATED = async (userId, files, label) => {
  const id = uuidv4();
  const body = await zipModelFiles(id, files);

  const data = new FormData();
  data.append('file', body);
  data.append('id', id);
  data.append('label', label);

  const response = await axiosInstance.post('/trainings/upload-external-model', data);
  return response?.data?.data;
};

export const uploadCommunityItemMethodOne = async (
  userId,
  image,
  files,
  label,
  gender,
  genres,
  countries,
  anonymous
) => {
  const id = uuidv4();
  const body = await zipModelFiles(id, files);
  const data = {
    id,
    label,
    gender,
    genres,
    countries,
    anonymous,
  };
  const formData = new FormData();
  formData.append('data', JSON.stringify(data));
  if (image) {
    formData.append('files', image);
  }
  if (body) {
    formData.append('files', body);
  }
  const response = await axiosInstance.post('/communityItems/upload-external-model', formData);
  return response?.data?.data;
};

export const uploadModelMethodTwo = async (userId, files, label) => {
  const id = uuidv4();
  const body = await zipModelFiles(id, files);

  const data = new FormData();
  data.append('file', body);
  data.append('id', id);
  data.append('label', label);

  const response = await axiosInstance.post('/trainings/upload-external-model-parallel', data);
  return response?.data?.data;
};

const initiateUpload = async data => {
  const response = await axiosInstance.post('/aws/initiateChunkUpload', data);
  return response?.data;
};

const completeUpload = async data => {
  await axiosInstance.post('/aws/completeChunkUpload', data);
};

const chunkUpload = async data => {
  const response = await axiosInstance.post('/aws/chunkUpload', data);
  return response?.data;
};

const uploadTrainingComplete = async data => {
  await axiosInstance.post('/trainings/upload-training-complete', data);
};

const uploadFileInChunk = async ({ bucket, key, file, modelId, tier, needMdx }) => {
  const uploadId = await initiateUpload(_.omitBy({ bucket, key, modelId, tier, needMdx }, _.isNil));

  const chunkSize = 75 * 1024 * 1024; // 75MB
  const parts = [];
  let start = 0;
  const totalChunks = Math.ceil(file.size / chunkSize);

  for (let index = 0; index < totalChunks; index++) {
    const chunk = file.slice(start, start + chunkSize);
    start += chunkSize;

    const formData = new FormData();
    formData.append('bucket', bucket);
    formData.append('chunk', chunk);
    formData.append('key', key);
    formData.append('chunkIndex', index);
    formData.append('uploadId', uploadId);

    const { ETag } = await chunkUpload(formData);
    parts.push({ PartNumber: index + 1, ETag });
  }

  await completeUpload({
    bucket,
    parts,
    key,
    uploadId,
  });
};

const checkValidUploadStatusTraining = async () => {
  try {
    await axiosInstance.get('/trainings/check-valid-upload-status');
    return { valid: true };
  } catch (e) {
    // possible types are "pending", "noCount", "generic"
    return { valid: false, type: e.response?.data || 'generic' };
  }
};

export const uploadModelMethodOne = async (userId, files, label) => {
  const id = uuidv4();
  const bucket = 'sori-models';
  const key = `${userId}/${id}.zip`;
  const file = await zipModelFiles(id, files);

  await uploadFileInChunk({ bucket, key, file });
  await postTrainings({ id, label, status: 'COMPLETE', external: true });
};

export const uploadTraining = async (userId, files, label, tier, needMdx) => {
  const id = uuidv4();
  const bucket = 'sori-audio-upload';
  const key = `training/${userId}/${id}.zip`;

  const uploadStatus = await checkValidUploadStatusTraining();
  if (!uploadStatus.valid) {
    return uploadStatus.type;
  }
  const file = await zipFiles(id, files);
  const filenames = [];
  for (let file of files) {
    filenames.push(file.name);
  }
  const fileList = JSON.stringify(filenames);
  await uploadFileInChunk({ bucket, key, file, modelId: id, tier, needMdx });
  await uploadTrainingComplete({ bucket, key, id, label, tier, needMdx, fileList });
  return null;
};

export const uploadTTSTraining = async (userId, files, label, tier, needMdx) => {
  const id = uuidv4();
  //const body = await zipFiles(id, files);

  const data = new FormData();
  for (let index in files) {
    data.append('files', files[index]);
  }
  data.append('id', id);
  data.append('label', label);
  data.append('tier', tier);
  data.append('needMdx', needMdx);

  const response = await axiosInstance.post('/ttsTrainings/upload', data);
  return response?.data?.data;
};

// TODO: delete after checking new method works
export const uploadTraining_DEPRECATED = async (files, label, tier) => {
  const id = uuidv4();
  const body = await zipFiles(id, files);

  const data = new FormData();
  data.append('file', body);
  data.append('id', id);
  data.append('label', label);
  data.append('tier', tier);
  data.append('needMdx', needMdx);

  const response = await axiosInstance.post('/trainings/upload', data);
  return response?.data?.data;
};

export const uploadErrorMessage = async message => {
  await axiosInstance.post('/trainings/post-message', { message });
};

export const uploadInference = async (
  userId,
  content,
  pitch,
  mixerSettings,
  needMdx,
  model,
  tier,
  outputFileFormat,
  community,
  label
) => {
  const extension = !content.youtubeLink ? (content.file.path ? content.file.path.split('.').pop() : 'wav') : 'mp3';
  const id = uuidv4();
  const key = `inference/${userId}/${id}.${extension}`;
  const data = {
    pitch,
    mixerSettings,
    id,
    key,
    label,
    needMdx,
    modelId: model.id,
    general: !model.userId,
    community,
    tier,
    youtubeLink: content.youtubeLink || '',
    outputFileFormat,
    sourceLabel: content.name,
  };
  const formData = new FormData();
  formData.append('data', JSON.stringify(data));

  let response;
  if (content.file) {
    formData.append('file', content.file);
    response = await axiosInstance.post('/inferences/upload', formData);
  } else {
    response = await axiosInstance.post('/inferences/upload-youtube', formData);
  }
  return response?.data?.inference;
};

export const uploadTTSInference = async (userId, text, model, variability, similarity, tier) => {
  const id = uuidv4();
  const data = {
    id,
    label: text.substring(0, 20),
    modelId: model.id,
    text,
    variability,
    similarity,
    tier,
  };

  const response = await axiosInstance.post('/ttsInferences/upload', data, { responseType: 'blob' });
  return response?.data;
};

//share a community item
export const shareVoice = async (userId, image, modelId, label, gender, genres, countries, anonymous) => {
  const id = uuidv4();
  const data = {
    id,
    label: label,
    modelId,
    gender,
    genres,
    countries,
    anonymous,
  };
  const formData = new FormData();
  formData.append('data', JSON.stringify(data));
  if (image) {
    formData.append('file', image);
  }
  let response;
  response = await axiosInstance.post('/communityItems/share', formData);
  return response?.data?.communityItem;
};

export const updateImage = async (id, file) => {
  const data = {
    id,
  };
  const formData = new FormData();
  formData.append('data', JSON.stringify(data));
  if (file) {
    formData.append('file', file);
  }
  let response;
  response = await axiosInstance.post('/communityItems/image-update', formData);
  return response;
};

//like a community item
export const likeCommunityItem = async communityItemId => {
  const data = {
    communityItemId,
  };
  let response;
  response = await axiosInstance.post('/communityItems/like', data);
  return response?.data?.likes;
};

//dislike a community item
export const dislikeCommunityItem = async communityItemId => {
  const data = {
    communityItemId,
  };
  let response;
  response = await axiosInstance.post('/communityItems/dislike', data);
  return response?.data?.likes;
};

// vocalExtract uploade
export const uploadVocalExtract = async (userId, file, uploadLabel, sourceLabel, outputFormat) => {
  const extension = file.path ? file.path.split('.').pop() : 'wav';
  const id = uuidv4();
  const key = `vocal-extract/${userId}/${id}.${extension}`;
  const data = {
    id,
    key,
    outputFormat,
    label: uploadLabel,
    sourceLabel,
  };

  const formData = new FormData();
  formData.append('data', JSON.stringify(data));
  formData.append('file', file);

  const response = await axiosInstance.post('/vocalExtracts/upload', formData);

  return response?.data?.vocalExtract;
};

// vocalExtract youtube uploade
export const uploadYoutubeVocalExtract = async (userId, content, uploadLabel, outputFormat) => {
  const extension = 'mp3';
  const id = uuidv4();
  const key = `vocal-extract/${userId}/${id}.${extension}`;
  const data = {
    id,
    key,
    label: uploadLabel,
    youtubeLink: content.youtubeLink,
    outputFormat,
    sourceLabel: content.name,
  };

  const formData = new FormData();
  formData.append('data', JSON.stringify(data));

  const response = await axiosInstance.post('/vocalExtracts/upload-youtube', formData);

  return response?.data?.vocalExtract;
};

export const getYoutubeInfo = async data => {
  const response = await axiosInstance.get(`/youtube/get-info?youtubeUrl=${data}`);
  return response?.data?.data;
};

// vocalExtract update by Id
export const updateVocalExtractById = async (id, update) => {
  const response = await axiosInstance.patch(`/vocalExtracts/${id}`, update);
  return response?.data?.data;
};

export const getVocalExtractSignedUrl = async (vocalExtractId, fileName, downloadName) => {
  const response = await axiosInstance.post('/vocalExtracts/get-signed-url', {
    vocalExtractId,
    fileName,
    downloadName,
  });
  return response?.data?.data;
};

export const checkVocalExtractCompletionById = async vocalExtractId => {
  const response = await axiosInstance.get(`/vocalExtracts/check-completion/${vocalExtractId}`);
  return response?.data?.data;
};

export const checkVocalExtractCompletion = async (userId, incompleteList) => {
  const response = await axiosInstance.get('/vocalExtracts/check-completion');
  return response?.data?.data;
};

export const getVocalExtractBlob = async (vocalExtractId, fileName) => {
  const response = await axiosInstance.post(
    '/vocalExtracts/get-vocalExtract-blob',
    {
      vocalExtractId,
      fileName,
    },
    { responseType: 'blob' }
  );
  return response?.data;
};

export const fetchPurchases = async () => {
  const response = await axiosInstance.get('/purchases');
  return response?.data?.data;
};

export const uploadContact = async (language, userId, contactType, email, content, currency) => {
  const response = await axiosInstance.post('/contact', {
    language,
    userId,
    contactType,
    email,
    content,
    currency,
  });
  return response;
};

export const updateUser = async data => {
  const response = await axiosInstance.patch(`/users`, data);
  return response?.data;
};

export const logout = async () => {
  const response = await axiosInstance.get(`/auth/logout`);
  return response?.data;
};

export const sendWelcomeEmail = async (email, currency) => {
  const response = await axiosInstance.post('/emails/send-welcome', {
    email,
    currency,
  });
  return response.status === 200;
};

export const updateEmailPreferences = async (userId, emailPreferences) => {
  const response = await axiosInstance.patch('/emails/update-preferences', {
    userId,
    emailPreferences,
  });
  return response?.data;
};

export const getEmailPreferences = async userId => {
  const response = await axiosInstance.get(`/emails/get-preferences/${userId}`);
  return response?.data?.data;
};

export const fetchTrainingsLabel = async () => {
  const response = await axiosInstance.get('/trainings/training-label');
  return response?.data?.data;
};

export const checkVocalExtractCompletionByIdArr = async vocalExtractIdArr => {
  const response = await axiosInstance.patch('/vocalExtracts/check-completion/id-array', { vocalExtractIdArr });
  return response?.data?.data;
};

export const checkValidUploadStatusVocalExtract = async uploadedFileNum => {
  try {
    await axiosInstance.get(`/vocalExtracts/check-valid-upload-status/${uploadedFileNum}`);
    return { valid: true };
  } catch (e) {
    return { valid: false };
  }
};
