import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
  loading: {
    alignSelf: 'center',
  },
  yesButton: {
    margin: '0 0.5rem 0 0',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    height: '2.5rem',
    cursor: 'pointer',
    border: '0.1rem solid #d1fe7575',
    borderRadius: '0.2rem',
    flex: 1,
    color: '#1e1e1e',
    backgroundColor: '#d1fe7575',
    '&:hover': {
      background: '#d1fe75',
      border: '0.1rem solid #d1fe75',
    },
  },
  noButton: {
    margin: '0 0 0 0.5rem',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    height: '2.5rem',
    cursor: 'pointer',
    border: '0.1rem solid #a4a4a4',
    color: '#1e1e1e',
    borderRadius: '0.2rem',
    flex: 1,
    '&:hover': {
      background: '#a4a4a4',
    },
  },
  buttonContainer: {
    display: 'flex',
    justifyContent: 'space-around',
  },
  label: {
    display: 'flex',
    marginBottom: '1.3rem',
  },
  labelInput: {
    flex: '1',
  },
  container: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    display: 'flex',
    width: 400,
    height: 620,
    //alignItems: 'stretch',
    flexDirection: 'column',
    backgroundColor: '#fff',
    padding: '2rem 2rem',
    wordBreak: 'keep-all!important',
    overflowY: 'auto',
    '&::-webkit-scrollbar': {
      width: '0.4em',
    },
    '&:active': {
      outline: 'none',
    },
    '&:focus': {
      outline: 'none',
    },
  },
  anonymousContainer: {
    //paddingTop: '1rem!important',
    marginBottom: '1.3rem',
    //height: 50,
    display: 'block',
  },
  radioButtonContainer: {
    [theme.breakpoints.down('sm')]: {
      height: '3rem',
    },
    display: 'flex!important',
    flexDirection: 'row!important',
    alignItems: 'center',
    justifyContent: 'start',
    height: '1rem',
    marginBottom: '10rem',
  },
  radioButtonLabel: {
    [theme.breakpoints.down('xs')]: {
      fontSize: '0.85rem!important',
    },
    marginRight: '1rem',
    marginLeft: '0.3rem',
    fontWeight: '300',
    //fontSize: '1.1rem!important',
    //color: '#fff',
    display: 'flex',
    fontSize: '1rem',
    alignItems: 'center',
  },
  header: {
    fontSize: '1.7rem',
    marginBottom: '0.7rem',
    //color: '#fff',
    fontWeight: '400',
    display: 'flex',
    alignItems: 'center',
  },
  separater: {
    borderBottomWidth: '1px',
    borderBottomColor: '#A4A4A4',
    borderBottomStyle: 'solid',
    //helight: '0.2rem',
    marginBottom: '1rem',
  },
  dragAndDropContainer: {
    marginTop: '0.5rem',
  },
  dragAndDrop: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    padding: '1rem 0',
    justifyContent: 'center',
  },
  dragAndDropText: {
    fontWeight: '300',
    fontSize: '1rem',
    color: '#8A8A8A',
  },
  dragAndDropButton: {
    marginTop: '0.8rem',
    fontWeight: '300',
    fontSize: '1rem',
    color: '#F2F2F2',
    backgroundColor: '#202020',
    borderRadius: '0.4rem',
    padding: '0.5rem 1.5rem',
    color: '#1D1D1D',
    backgroundColor: '#F0F0F0',
  },
  coverImg: {
    userSelect: 'none',
    width: '5em',
    height: '5em',
    display: 'inline-block',
    fill: 'currentcolor',
    flexShrink: '0',
    transition: 'fill 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms',
    fontSize: '1.5rem',
    borderRadius: '0.2rem',
  },
  deleteButtonImg: {
    //marginTop: "3.2rem",
    userSelect: 'none',
    display: 'inline-block',
    marginLeft: '0.5rem',
    //fill: "currentcolor",
    flexShrink: '0',
    //transition: "fill 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms",
    //fontSize: "px",
    cursor: 'pointer',
    borderRadius: '0.1rem',
    //color: '#fff',
  },
  recordButtonContainer: {
    width: '24rem',
    maxWidth: '100%',
    display: 'flex',
    justifyContent: 'space-between',
    marginTop: '0.6rem',
    marginBottom: '2rem',
  },
  imageContainer: {
    [theme.breakpoints.down('sm')]: {
      marginLeft: '0.5rem',
    },
    width: '28rem',
    display: 'flex',
    marginLeft: '0.8rem',
    marginTop: '2rem',
    marginBottom: '3.2rem',
    alignItems: 'flex-end',
  },
}));

export { useStyles };
