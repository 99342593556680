import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
  loading: {
    alignSelf: 'center',
  },
  yesButton: {
    margin: '0 0.5rem 0 0',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    height: '2.5rem',
    cursor: 'pointer',
    border: '0.1rem solid #d1fe7575',
    borderRadius: '0.2rem',
    flex: 1,
    color: '#1e1e1e',
    backgroundColor: '#d1fe7575',
    '&:hover': {
      background: '#d1fe75',
      border: '0.1rem solid #d1fe75',
    },
  },
  noButton: {
    margin: '0 0 0 0.5rem',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    height: '2.5rem',
    cursor: 'pointer',
    border: '0.1rem solid #a4a4a4',
    color: '#1e1e1e',
    borderRadius: '0.2rem',
    flex: 1,
    '&:hover': {
      background: '#a4a4a4',
    },
  },
  buttonContainer: {
    display: 'flex',
    justifyContent: 'space-around',
  },
  message: {
    marginLeft: '0.3rem',
    marginBottom: '2rem',
  },
  container: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    display: 'flex',
    alignItems: 'stretch',
    flexDirection: 'column',
    backgroundColor: '#fff',
    padding: '2rem 2rem',
    wordBreak: 'keep-all!important',
    '&:active': {
      outline: 'none',
    },
    '&:focus': {
      outline: 'none',
    },
  },
}));

export { useStyles };
