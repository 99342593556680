import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { useStyles } from './GenerateTTSPage.styles';

import _ from 'lodash';
import ReactJkMusicPlayer from 'react-jinke-music-player';
import 'react-jinke-music-player/assets/index.css';
import ReactTimeAgo from 'react-time-ago';

import Grid from '@material-ui/core/Grid';
import CircularProgress from '@material-ui/core/CircularProgress';
import AddIcon from '@mui/icons-material/Add';
import HighlightOffIcon from '@mui/icons-material/HighlightOff';
import TuneIcon from '@mui/icons-material/Tune';

import {
  checkTrainingCompletion,
  fetchSubscriptionInfo,
  checkTTSInferenceCompletion,
  uploadTTSInference,
  fetchTrainings,
  getCommunitySampleSignedUrl,
} from '../../services/page.services';

import { checkValidLoginStatus } from '../../utils/user.utils';
import { appActions } from '../../actions/app.actions.js';
import { userActions } from '../../actions/user.actions.js';
import { getDefaultModel } from '../../constants/model.constants';
import SelectTTSVoiceModal from '../layout/SelectTTSVoiceModal';
import TTSSettingModal from '../layout/TTSSettingModal.js';

import coverImg from '../../img/cover.png';
import FreeInferenceMessageModal from '../layout/FreeInferenceMessageModal.js';

function GenerateTTSPage() {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const user = useSelector(state => state.user);
  const app = useSelector(state => state.app);
  const userId = user?.id;
  const tierToShowOutputFormat = ['PREMIUM', 'PRO', 'ADMIN', 'CUSTOM'];
  const isAdmin = user?.subscription?.type === 'ADMIN';
  const isCustom = user?.subscription?.type === 'CUSTOM';

  const intervalIDRef = React.useRef(null);
  const [loadingText, setLoadingText] = useState('');

  const [selectedVoiceType, setSelectedVoiceType] = useState(app.selectedModel ? 'community' : '');
  const [selectedModel, setSelectedModel] = useState(app.selectedModel);

  const [text, setText] = useState('');
  const [ttsCharactersRemaining, setTtsCharactersRemaining] = useState(0);
  const [ttsCharactersPerInference, setTtsCharactersPerInference] = useState(20);
  const [inferenceInProgress, setInferenceInProgress] = useState(false);
  const [content, setContent] = useState({});

  const [selectVoiceModalProps, setSelectVoiceModalProps] = useState({});
  const [completedAudio, setCompletedAudio] = React.useState();
  const [openFreeInferenceModal, setOpenFreeInferenceModal] = useState(false);

  const [variability, setVariability] = useState(50);
  const [similarity, setSimilarity] = useState(75);
  const [settingModalProps, setSettingModalProps] = useState({
    variability: variability,
    similarity: similarity,
    setVariability: setVariability,
    setSimilarity: setSimilarity,
  });

  //play
  const [selectedAudio, setSelectedAudio] = React.useState();
  const [playing, setPlaying] = useState(false);
  const [selectedCommunityId, setSelectedCommunityId] = useState('');

  useEffect(() => () => intervalIDRef.current && clearInterval(intervalIDRef.current), []);

  useEffect(() => {
    return () => {
      dispatch(appActions.clearSelectedCommunityItem());
    };
  }, []);

  useEffect(() => {
    if (userId) (async () => initPage())();
  }, [userId]);

  //add event listened to be triggered whenever the played song ends
  useEffect(() => {
    if (selectedAudio) {
      selectedAudio.addEventListener('ended', () => {
        setSelectedCommunityId('');
        setPlaying(false);
      });
    }
    return () => {
      if (selectedAudio) {
        selectedAudio.pause();
        selectedAudio.removeEventListener('ended', () => {
          setSelectedCommunityId('');
          setPlaying(false);
        });
      }
    };
  }, [selectedAudio]);

  //when people click play button
  useEffect(() => {
    if (selectedAudio) {
      playing ? selectedAudio.play() : selectedAudio.pause();
    }
  }, [playing]);

  const defaultModel = getDefaultModel(t);

  const checkInferenceInProgress = async () => {
    let inferences = await checkTTSInferenceCompletion();
    const inProgress = _.some(inferences, { status: 'PENDING' });
    setInferenceInProgress(inProgress);
  };

  const getBlobFromS3Url = async url => {
    const res = await fetch(url);
    return res.blob();
  };

  const initPage = async () => {
    try {
      if (!isAdmin && !isCustom) {
        await checkInferenceInProgress();
      }
      await checkRemainingCount();
      await checkTrainingCompletion();
      const trainings = await fetchTrainings();
      const communityTrainings = await fetchTrainings();
      const availableTraining = _.sortBy(
        _.map(_.filter(trainings, { status: 'COMPLETE' }), v => ({
          ...v,
          category: 'my voices',
        })),
        ['label']
      );
      const availableCommunityTraining = _.sortBy(
        _.map(_.filter(communityTrainings, { status: 'COMPLETE' }), v => ({
          ...v,
          category: 'community',
        })),
        ['label']
      );
      const completeTrainingList = availableTraining.concat(availableCommunityTraining);
    } catch (e) {
      console.log(e);
      dispatch(appActions.openMessageModal(t('modal.pageLoadFail'), true));
    }
  };

  const checkRemainingCount = async () => {
    let subscriptionInfo = await fetchSubscriptionInfo();
    dispatch(userActions.updateSubscription(subscriptionInfo));
    console.log('subscriptionInfo: ', subscriptionInfo);
    setTtsCharactersRemaining(subscriptionInfo?.ttsCharactersRemaining || 0);
    setTtsCharactersPerInference(subscriptionInfo?.ttsCharactersPerInference || 20);
    return subscriptionInfo;
  };

  const checkCompleteForm = () => {
    let message = '';
    if (inferenceInProgress) {
      message = t('ttsInferenceTab.modal.inProgress');
    } else if (text.length > ttsCharactersRemaining) {
      message = t('ttsInferenceTab.modal.noRemaining');
    } else if (text.length > ttsCharactersPerInference) {
      message = t('ttsInferenceTab.modal.exceedTextSize', {
        characterLimitPerInference: ttsCharactersPerInference,
      });
    } else if (!selectedModel?.id) {
      message = t('ttsInferenceTab.modal.noModalSelected');
    } else if (text.length === 0) {
      message = '텍스트를 입력하세요';
    }
    if (message) {
      dispatch(appActions.openMessageModal(message));
      return false;
    }
    return true;
  };

  const handleSubmit = async e => {
    if (!checkValidLoginStatus(userId, dispatch)) return;
    if (!checkCompleteForm()) return;
    setCompletedAudio(null);
    setLoadingText(t('ttsInferenceTab.submit.uploading'));
    setInferenceInProgress(true);

    console.log(`selectedModel = ${selectedModel}`);
    try {
      const inferenceBlob = await uploadTTSInference(
        userId,
        text,
        selectedModel,
        variability,
        similarity,
        user.subscription.type
      );
      setLoadingText(t('ttsInferenceTab.submit.inProgress'));
      console.log(inferenceBlob);
      setCompletedAudio(
        _.compact([
          inferenceBlob && {
            name: `output`,
            musicSrc: URL.createObjectURL(inferenceBlob.slice(0, inferenceBlob.size, `audio/mpeg`)),
            extension: 'mp3',
          },
        ])
      );
      setLoadingText('');
      checkRemainingCount();
      setInferenceInProgress(false);
    } catch (e) {
      dispatch(appActions.openMessageModal(t('ttsInferenceTab.submit.tryAgain')));
      setLoadingText('');
      checkRemainingCount();
      setInferenceInProgress(false);
      return;
    }
  };

  const customDownloader = downloadInfo => {
    const downloadingAudio = _.find(completedAudio, {
      musicSrc: downloadInfo.src,
    });
    const link = document.createElement('a');
    link.href = downloadInfo.src;
    link.download = `${downloadingAudio.name}.${downloadingAudio.extension}`;
    document.body.appendChild(link);
    link.click();
  };

  const onBeforeDestroy = () => {
    return new Promise((_resolve, reject) => {
      setCompletedAudio(null);
      reject();
    });
  };

  const handleSelectVoice = async () => {
    if (!checkValidLoginStatus(userId, dispatch)) return;
    setSelectVoiceModalProps({
      open: true,
      onSelectVoice: async (selectedVoice, selectedVoiceType) => {
        //setSelectVoiceModalProps({ open: true, loading: true });
        setSelectedModel(selectedVoice);
        setSelectedVoiceType(selectedVoiceType);
        setSelectVoiceModalProps({ open: false });
      },
      onSelectVoiceModalClose: () => {
        setSelectVoiceModalProps({ open: false });
      },
    });
  };

  const handleSetting = async () => {
    setSettingModalProps({
      open: true,
      setVariability: setVariability,
      setSimilarity: setSimilarity,
      variability: variability,
      similarity: similarity,
      onSettingModalClose: () => {
        setSettingModalProps({ open: false });
      },
    });
  };

  console.log(' ttsCharactersRemaining : ', ttsCharactersRemaining);
  const classes = useStyles();
  return (
    <>
      <FreeInferenceMessageModal open={openFreeInferenceModal} setOpenFreeInferenceModal={setOpenFreeInferenceModal} />
      <SelectTTSVoiceModal {...selectVoiceModalProps} />
      <TTSSettingModal {...settingModalProps} />
      <div>
        <div className={classes.pageTitle}>{t('ttsInferenceTab.title')}</div>
        <div className={classes.separater} />
        <Grid container spacing={3}>
          <Grid style={{ marginBottom: '0.5rem' }} item xs={12} sm={12} md={12}>
            {selectedModel && (
              <Grid className={classes.card} item container key={selectedModel.id}>
                <Grid item xs={'auto'} sm={'auto'} md={'auto'}>
                  <img
                    className={classes.coverImg}
                    src={selectedModel.image ? selectedModel.image : coverImg}
                    alt="cover-img"
                  />
                </Grid>
                <Grid
                  container
                  className={classes.cardSecondColumn}
                  //style={{ border: "1px solid #fafafa" }}
                  item
                  xs
                  sm
                  md
                >
                  <Grid container item>
                    <Grid container item xs={9} sm={10} md={10}>
                      <div className={classes.cardLabel}>{selectedModel.label}</div>
                    </Grid>
                    <Grid
                      container
                      item
                      className={classes.playButtonGrid}
                      xs={3}
                      sm={2}
                      md={2}
                      //style={{ border: "1px solid #fafafa" }}
                    >
                      <HighlightOffIcon
                        onClick={() => {
                          setSelectedCommunityId('');
                          setPlaying(false);
                          setSelectedModel(null);
                        }}
                        className={classes.cancelButtonImg}
                      />
                    </Grid>
                  </Grid>
                  <Grid container item>
                    <div className={classes.cardUsername}>{selectedModel.username ? selectedModel.username : ''}</div>
                  </Grid>
                  <Grid
                    container
                    item
                    style={{
                      maxHeight: '2rem',
                    }}
                  >
                    <Grid
                      container
                      item
                      xs={9}
                      sm={9}
                      md={9}
                      style={{
                        alignItems: 'flex-end',
                        maxHeight: '1.1rem',
                      }}
                    >
                      <div className={classes.cardTimestamp}>
                        <ReactTimeAgo date={selectedModel.createdAt} locale="en-US" />
                      </div>
                    </Grid>
                    <Grid
                      container
                      item
                      xs={3}
                      sm={3}
                      md={3}
                      className={classes.cardLikes}
                      style={{
                        maxHeight: '2rem',
                        minHeight: '1.5rem',
                      }}
                    ></Grid>
                  </Grid>
                </Grid>
              </Grid>
            )}
            {!selectedModel && (
              <div className={classes.selectModelOuterContainer} onClick={() => handleSelectVoice()}>
                <div className={classes.selectModelButton}>
                  <div className={classes.selectModelContainer}>
                    <div className={classes.dragAndDropText}>{t('ttsInferenceTab.stepOne.dragAndDropText')}</div>
                    <AddIcon className={classes.addButtonImg} />
                  </div>
                </div>
              </div>
            )}
          </Grid>
          <Grid container xs={12} sm={12} md={12}>
            <textarea
              //style={{ border: '1px solid #fafafa' }}
              className={classes.ttsTextArea}
              placeholder={t('ttsInferenceTab.stepOne.ttsTextAreaPlaceholder')}
              rows={16}
              value={text}
              onChange={e => {
                if (checkValidLoginStatus(userId, dispatch)) {
                  setText(e.target.value);
                }
              }}
            />
          </Grid>

          <Grid
            xs={12}
            sm={12}
            md={12}
            className={classes.buttonContainer}
            style={{ maxWidth: '51rem', marginBottom: '1rem' }}
          >
            <Grid item>
              <div className={classes.button} onClick={handleSubmit} disabled={inferenceInProgress}>
                <div className={classes.submitTypo}>
                  {inferenceInProgress
                    ? t('ttsInferenceTab.submit.alreadyInProgress')
                    : t('ttsInferenceTab.submit.start')}
                </div>
              </div>
            </Grid>
            <Grid container justifyContent="flex-end" direction="column">
              <Grid className={classes.buttonRemainingText}>
                {text.length} / {ttsCharactersPerInference}
                {' ' + t('ttsInferenceTab.submit.remainingCountUnit')}
              </Grid>
              {console.log(user.subscription?.status)}
              {userId && ttsCharactersRemaining > 0 && (
                <Grid item className={classes.buttonRemainingText}>
                  {t('ttsInferenceTab.submit.remainingMonthly')}: {ttsCharactersRemaining}
                </Grid>
              )}
            </Grid>
            <Grid container xs={4} sm={4} md={4} className={classes.settingGrid} justifyContent="flex-end">
              <div
                className={classes.settingButton}
                onClick={() => {
                  if (checkValidLoginStatus(userId, dispatch)) {
                    handleSetting();
                  }
                }}
              >
                <TuneIcon fontSize="20rem" className={classes.tuneIcon} />
                <div className={classes.settingTypo}>{t('ttsInferenceTab.submit.setting')}</div>
              </div>
            </Grid>
          </Grid>
          {loadingText && (
            <Grid className={classes.buttonGrid} item xs={12} sm={12} md={12}>
              <div className={classes.laodingContainer}>
                <CircularProgress size="1.4rem" />
                <span className={classes.laodingText}>{loadingText}</span>
              </div>
            </Grid>
          )}

          {completedAudio && (
            <ReactJkMusicPlayer
              mode="full"
              theme="light"
              autoPlay={false}
              showPlayMode={false}
              showThemeSwitch={false}
              showMiniModeCover={false}
              autoHiddenCover
              onBeforeDestroy={onBeforeDestroy}
              spaceBar={true}
              showDestroy={true}
              responsive={false}
              showReload={false}
              toggleMode={false}
              remove={false}
              customDownloader={customDownloader}
              audioLists={completedAudio}
            />
          )}
        </Grid>
      </div>
    </>
  );
}

export default GenerateTTSPage;
