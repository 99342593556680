import React from 'react';
import ReactDOM from 'react-dom/client';
import { BrowserRouter } from 'react-router-dom';
import { Provider } from 'react-redux';
import store from './store';
import './index.css';
import App from './App';
import axiosInstance from './services/axiosInstance';
import { userActions } from './actions/user.actions';

// replace console.* for disable log on production
if (process.env.NODE_ENV === 'production') {
  console.log = () => {};
  console.error = () => {};
  console.debug = () => {};
}

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <Provider store={store}>
    <BrowserRouter>
      <App />
    </BrowserRouter>
  </Provider>
);

const { dispatch } = store; // direct access to redux store.

axiosInstance.interceptors.response.use(
  response => {
    return response;
  },
  error => {
    console.log('in error response: ', error);
    if (error.response?.status === 401) {
      dispatch(userActions.logout());
      return Promise.reject(error);
    }
    return Promise.reject(error);
  }
);
