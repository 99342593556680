import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import Modal from '@material-ui/core/Modal';
import { useStyles } from './DownloadModal.styles.js';
import DownloadIcon from '@mui/icons-material/FileDownload';
import CircularProgress from '@mui/material/CircularProgress';
import { getInferenceSignedUrl, getVocalExtractSignedUrl, getTTSInferenceBlob } from '../../services/page.services';

export default function DownloadModal(props) {
  const classes = useStyles();
  const { t } = useTranslation();
  const [loading, setLoading] = React.useState(false);
  const [fontStyle, setFontStyle] = useState('TheJamsil');

  useEffect(() => {
    const defaultLanguage = localStorage.getItem('selectedLanguage') || navigator.language.split('-')[0];
    const defaultFont = defaultLanguage === 'ja' ? 'MPlus1' : 'TheJamsil';
    setFontStyle(defaultFont);
  }, []);

  const getNames = (id, label, type, extension, tag) => {
    const subLabel = (() => {
      if (type === 'voice') return '-voice';
      else if (type === 'instrumental') return '-instrument';
      else if (type === 'backVocal') return '-backvocal';
      else return '';
    })();
    return {
      s3FileName: `${id}${subLabel}.${extension}`,
      downloadName: `${tag}${label}${subLabel}.${extension}`,
    };
  };

  const handleDownload = async type => {
    const { id, label, alignment, outputFormat, tag } = props;
    setLoading(true);
    const tempLink = document.createElement('a');
    try {
      if (alignment == 'vocalExtract') {
        const { s3FileName, downloadName } = getNames(id, label, type, outputFormat, tag);
        tempLink.href = await getVocalExtractSignedUrl(id, s3FileName, downloadName);
      } else if (alignment == 'music') {
        const { s3FileName, downloadName } = getNames(id, label, type, outputFormat, tag);
        tempLink.href = await getInferenceSignedUrl(id, s3FileName, downloadName);
      } else if (alignment == 'speech') {
        const inferenceBlob = await getTTSInferenceBlob(id);
        const url = URL.createObjectURL(inferenceBlob.slice(0, inferenceBlob.size, `audio/mpeg`));
        console.log(inferenceBlob);
        tempLink.href = url;
        tempLink.download = label.replaceAll(' ', '_') + '.mp3';
      }
      if (tempLink.href) {
        tempLink.click();
        URL.revokeObjectURL(tempLink.href);
      }
    } catch (e) {
      setLoading(false);
      console.log('e : ', e);
    }
    setLoading(false);
  };

  return (
    <Modal className={fontStyle} open={!!props.open} onClose={() => props.onDownloadClose()}>
      <div className={classes.container}>
        {!loading && (
          <>
            {props.needMdx && props.alignment == 'music' && (
              <div className={classes.downloadButton} onClick={() => handleDownload('combined')}>
                {t('modal.combined')} <DownloadIcon />
              </div>
            )}
            {props.alignment !== 'speech' && (
              <div className={classes.downloadButton} onClick={() => handleDownload('voice')}>
                {t('modal.vocal')} <DownloadIcon />
              </div>
            )}
            {((props.needMdx && props.alignment == 'music') || props.alignment == 'vocalExtract') && (
              <div className={classes.downloadButton} onClick={() => handleDownload('instrumental')}>
                {t('modal.instrument')}
                <DownloadIcon />
              </div>
            )}
            {props.alignment == 'vocalExtract' && (
              <div className={classes.downloadButton} onClick={() => handleDownload('backVocal')}>
                {t('modal.backVocal')}
                <DownloadIcon />
              </div>
            )}

            {props.alignment == 'speech' && (
              <div className={classes.downloadButton} onClick={() => handleDownload('speech')}>
                {props.label.replaceAll(' ', '_') + '.mp3'}
                <DownloadIcon />
              </div>
            )}
          </>
        )}
        {loading && <CircularProgress />}
      </div>
    </Modal>
  );
}
