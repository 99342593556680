import React, { useEffect, useState } from 'react';
import Modal from '@material-ui/core/Modal';
import { useDispatch } from 'react-redux';
import { useStyles } from './CustomMessageModal.styles.js';
import { appActions } from '../../actions/app.actions';
import { useTranslation } from 'react-i18next';

export default function CustomMessageModal(props) {
  const dispatch = useDispatch();
  const classes = useStyles();
  const [fontStyle, setFontStyle] = useState('TheJamsil');
  const { t } = useTranslation();

  useEffect(() => {
    const defaultLanguage = localStorage.getItem('selectedLanguage') || navigator.language.split('-')[0];
    const defaultFont = defaultLanguage === 'ja' ? 'MPlus1' : 'TheJamsil';
    setFontStyle(defaultFont);
  }, []);

  return (
    <Modal className={fontStyle} open={!!props.open} onClose={() => dispatch(appActions.closeCustomMessageModal())}>
      <div className={classes.customMessageContainer}>
        {props.usage === 'vocalExtractorManyFilesError' && (
          <>
            <div className={classes.customMessage}>
              {props.customMessage[0] +
                ' ' +
                t('vocalExtractorTab.modal.tooManyFiles.0') +
                ' ' +
                props.customMessage[1] +
                props.customMessage[2] +
                ' ' +
                t('vocalExtractorTab.modal.tooManyFiles.1')}
            </div>
          </>
        )}
        {props.usage === 'vocalExtractFailed' && (
          <>
            <div className={classes.customMessage}>{props.customMessage[0]}</div>
            {props.customMessage[1].map(item => (
              <div className={classes.customMessage}>{item}</div>
            ))}
          </>
        )}
      </div>
    </Modal>
  );
}
