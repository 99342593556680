import { makeStyles } from '@material-ui/core/styles';
export const drawerWidth = 240;
const useStyles = makeStyles(theme => ({
  main: {
    position: 'absolute',
    [theme.breakpoints.up('md')]: {
      marginLeft: '240px',
      width: 'calc(100% - 240px)',
    },
    top: '65px',
    bottom: '0',
    backgroundColor: '#1E1E1E',
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
  },
  innerMain: {
    flex: 1,
    padding: '0 2.5rem',
  },
  layout: {},
  footer: {
    color: '#fff',
    marginTop: '100px',
    borderTop: '1px solid #4a4a4a',
    padding: '13px 20px',
  },
  companyInfo: {
    fontWeight: '300',
    marginRight: '1rem',
    fontSize: '0.7rem',
  },
}));

export { useStyles };
