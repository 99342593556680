import React, { useEffect, useState } from 'react';
import Modal from '@material-ui/core/Modal';
import { useStyles } from './FreeTrainingMessageModal.styles';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import textLogoWhite from '../../img/textLogo.png';

export default function FreeTrainingMessageModal(props) {
  const { t } = useTranslation();
  const classes = useStyles();
  const navigate = useNavigate();
  const [fontStyle, setFontStyle] = useState('TheJamsil');

  useEffect(() => {
    const defaultLanguage = localStorage.getItem('selectedLanguage') || navigator.language.split('-')[0];
    const defaultFont = defaultLanguage === 'ja' ? 'MPlus1' : 'TheJamsil';
    setFontStyle(defaultFont);
  }, []);

  const messages = t('freeTrainingMessageModal.messages', { returnObjects: true });

  return (
    <Modal className={fontStyle} open={!!props.open} onClose={() => props.setOpenFreeTrainingModal(false)}>
      <div className={classes.messageContainer}>
        <img className={classes.sorisoriAIImg} src={textLogoWhite} alt="sorisoriAI" />
        <div className={classes.messageOneContainer}>
          {messages.map(message => (
            <div className={classes.messageOne}>{message}</div>
          ))}
        </div>
        <div className={classes.button} onClick={() => navigate('/subscription')}>
          {t('freeTrainingMessageModal.button')}
        </div>
      </div>
    </Modal>
  );
}
