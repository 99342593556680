import { Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
  container: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 'fit-content',
    height: 'fit-content',
    borderRadius: '0.5rem',
    display: 'flex',
    flexDirection: 'column',
    backgroundColor: '#1E1E1E',
    padding: '2rem',
    wordBreak: 'keep-all!important',
    '&:active': {
      outline: 'none',
    },
    '&:focus': {
      outline: 'none',
    },
  },
  modalTitle: {
    fontSize: '1.5rem',
    color: '#fff',
    width: '100%',
    textAlign: 'center',
  },
  separater: {
    borderBottomWidth: '1px',
    borderBottomColor: '#A4A4A4',
    borderBottomStyle: 'solid',
    marginTop: '1.5rem',
  },
  mixSeparater: {
    flex: '1',
    height: '1px',
    backgroundColor: '#A4A4A4',
  },
  pitchContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    marginTop: '1.5rem',
  },
  inputContainer: {
    marginBottom: '1rem',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  inputNameContainer: {
    marginRight: '1rem',
    width: '10rem',
    display: 'flex',
    alignItems: 'center',
  },
  inputName: {
    lineHeight: '1rem',
    color: '#fff',
    fontSize: '0.8rem',
  },
  tooltipImg: {
    marginRight: '1rem',
    width: '1.3rem',
  },
  tooltipContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'stretch',
    padding: '1rem',
    color: '#161616',
  },
  tooltipTextContainer: {
    display: 'flex',
  },
  tooltipText: {
    fontSize: '1.1rem',
    fontWeight: '300',
    margin: '0.3rem 0',
    lineHeight: '1.3rem',
  },
  tooltipTextBullet: {
    marginLeft: '0.5rem',
    marginRight: '0.8rem',
  },
  alertOuterContainer: {
    backgroundColor: '#160B0B',
    borderRadius: '0.4rem',
    padding: '1rem',
    marginTop: '1rem',
  },
  alertContainer: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    color: '#F4C7C7',
    fontSize: '0.8rem',
    fontWeight: '200',
  },
  mixToggleOuterContainer: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    margin: '1.5rem 0',
  },
  mixToggleContainer: {
    color: '#fff',
    fontSize: '0.8rem',
    justifyContent: 'center',
    alignItems: 'center',
    display: 'flex',
    margin: '0 1rem',
  },
  mixToggle: {
    width: '28px',
    height: '16px',
    marginLeft: '0.5rem',
    padding: '0',
    boxSizing: 'border-box',
    backgroundColor: '#818181',
    borderRadius: '16px',
    border: 'none',
    display: 'inline-block',
    '&[data-state="checked"]': {
      backgroundColor: '#CAFF73',
    },
  },
  mixToggleThumb: {
    boxSizing: 'border-box',
    display: 'block',
    width: '12px',
    height: '12px',
    left: '2px',
    borderRadius: '16px',
    backgroundColor: '#FFF',
    position: 'relative',
    transition: 'all 120ms',
    '&[data-state="checked"]': {
      left: '14px',
      backgroundColor: '#1E1E1E',
    },
  },
  numberFieldRoot: {
    fontSize: '0.875rem',
  },
  numberFieldGroup: {
    display: 'flex',
    alignItems: 'center',
  },
  numberFieldInput: {
    [theme.breakpoints.down('xs')]: {
      width: '3rem',
    },
    padding: '0.25rem 0.5rem',
    lineHeight: '1.5',
    border: '2px solid #818181',
    borderRadius: '8px',
    backgroundColor: '#fff',
    color: 'black',
    boxShadow: '0 1px 2px 0 rgba(0 0 0 / 0.05)',
    overflow: 'hidden',
    width: '4rem',
    margin: '0 0.5rem',
    fontFamily: 'inherit',
    fontSize: 'inherit',
    textAlign: 'center',
    transition: 'all 150ms',
    '&:focus': {
      outline: '2px solid #CAFF73',
    },
  },
  numberFieldButton: {
    background: '#F0F0F0',
    border: '2px solid #818181',
    borderRadius: '999px',
    width: '24px',
    height: '24px',
    display: 'flex',
    flexDirection: 'row nowrap',
    justifyContent: 'center',
    alignItems: 'center',
    transition: 'all 150ms',
    '&:hover': {
      cursor: 'pointer',
      borderColor: '#CAFF73',
      background: '#CAFF73',
    },
  },
  disabledButton: {
    color: '#818181 !important',
    '&:hover': {
      cursor: 'pointer',
      borderColor: '#818181',
      background: '#F0F0F0',
    },
  },
  disabledInput: {
    color: '#818181 !important',
  },
  demoOuterContainer: {
    backgroundColor: '#141414',
    borderRadius: '0.4rem',
    padding: '1rem 0.5rem 0.8rem 0.5rem',
    margin: '0.5rem 0',
  },
  demoContainer: {
    color: '#fff',
    fontSize: '0.8rem',
    fontWeight: '200',
    textAlign: 'center',
  },
  mixStatus: {
    color: '#AAAAAA',
    paddingTop: '0.5rem',
    fontSize: '0.7rem',
  },
  demoLabel: {
    color: '#AAAAAA',
    fontSize: '0.75rem',
    fontWeight: '300',
    textAlign: 'center',
    paddingTop: '0.5rem',
    width: '15rem',
    margin: '0 auto',
  },
  upgradeButton: {
    width: 'fit-content',
    padding: '0.6rem 1rem',
    fontSize: '0.8rem',
    backgroundColor: '#CAFF73',
    borderRadius: '0.2rem',
    cursor: 'pointer',
    margin: '0 auto',
    marginTop: '1.5rem',
  },
  upgradeDialog: {
    '& .MuiPaper-root': {
      backgroundColor: '#1E1E1E',
      padding: '1.2rem 1.5rem',
    },
  },
  dialogTitle: {
    color: '#fff',
    textAlign: 'center',
    fontSize: '0.9rem',
    paddingTop: '0.5rem',
  },
  dialogText: {
    color: '#AAAAAA',
    textAlign: 'center',
    fontWeight: 300,
    fontSize: '0.8rem',
    paddingTop: '0.6rem',
    maxWidth: '18rem',
  },
}));

export { useStyles };
