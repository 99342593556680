import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
  container: {
    [theme.breakpoints.down('xs')]: {
      width: '85%',
    },
    [theme.breakpoints.only('sm')]: {
      width: '65%',
    },
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    maxWidth: '35rem',
    width: '50%',
    height: 'fit-content',
    borderRadius: '0.5rem',
    display: 'flex',
    flexDirection: 'column',
    backgroundColor: '#1E1E1E;',
    padding: '2rem',
    wordBreak: 'keep-all!important',
    '&:active': {
      outline: 'none',
    },
    '&:focus': {
      outline: 'none',
    },
  },
  label: {
    fontSize: '1.2rem',
    color: '#fff',
    width: '100%',
  },
  separater: {
    borderBottomWidth: '1px',
    borderBottomColor: '#A4A4A4',
    borderBottomStyle: 'solid',
    margin: '1rem 0',
  },
  accordion: {
    color: '#fff !important',
    backgroundColor: '#1E1E1E !important',
    marginBottom: '0.7rem',
    boxShadow: 'none !important',
    border: 'none',
  },
  accordionIcon: {
    color: '#fff',
  },
  accordionTitle: {
    fontSize: '0.9rem',
    flexDirection: 'row-reverse',
    padding: '0 !important',
    '& .MuiAccordionSummary-expandIconWrapper.Mui-expanded': {
      transform: 'rotate(90deg)',
    },
  },
  accordionContent: {
    flexDirection: 'column',
    maxHeight: '12rem',
    overflowY: 'auto',
    msOverflowStyle: 'none',
    scrollbarWidth: 'none',
    '&::-webkit-scrollbar': {
      display: 'none',
    },
  },
  fileNames: {
    fontSize: '0.75rem',
    fontWeight: 300,
    display: 'block',
    padding: '0.6rem 0',
    borderTop: '1px solid #5C5C5C',
  },
  info: {
    display: 'flex',
    marginTop: '0.5rem',
  },
  infoTitle: {
    color: '#fff',
    fontSize: '0.9rem',
    fontWeight: 400,
  },
  infoValue: {
    color: '#fff',
    fontSize: '0.9rem',
    fontWeight: 300,
    paddingLeft: '0.3rem',
  },
}));

export { useStyles };
