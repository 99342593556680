import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import Modal from '@material-ui/core/Modal';
import { appActions } from '../../actions/app.actions';
import { useStyles } from './MessageModalV2.styles';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import textLogoWhite from '../../img/textLogo.png';

export default function MessageModalV2(props) {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const classes = useStyles();
  const navigate = useNavigate();
  const [fontStyle, setFontStyle] = useState('TheJamsil');

  useEffect(() => {
    const defaultLanguage = localStorage.getItem('selectedLanguage') || navigator.language.split('-')[0];
    const defaultFont = defaultLanguage === 'ja' ? 'MPlus1' : 'TheJamsil';
    setFontStyle(defaultFont);
  }, []);

  return (
    <Modal className={fontStyle} open={!!props.open} onClose={() => dispatch(appActions.closeMessageModalV2())}>
      <div className={classes.messageContainer}>
        <img className={classes.sorisoriAIImg} src={textLogoWhite} alt="sorisoriAI" />
        <div className={classes.messageOneContainer}>
          <div className={classes.messageOne}>{props.message}</div>
        </div>
        {props.showProfileButton && (
          <div
            className={classes.button}
            onClick={() => {
              navigate('/profile');
              dispatch(appActions.closeMessageModalV2());
            }}
          >
            {t('modal.gotoProfile')}
          </div>
        )}
      </div>
    </Modal>
  );
}
