import { makeStyles } from '@material-ui/core/styles';
export const drawerWidth = 240;
const useStyles = makeStyles(theme => ({
  drawerPaper: {
    width: drawerWidth,
    backgroundColor: '#161616',
    color: '#fff',
  },
  drawer: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    flex: 1,
    backgroundColor: '#161616',
    padding: '0.5rem',
  },
  logo: {
    width: '80%',
    margin: '15px',
    cursor: 'pointer',
  },
  separater: {
    margin: '0.2rem 1rem',
    borderTop: '0.1rem solid #fff',
  },
  drawerListItem: {
    marginBottom: '0rem',
    height: '46px',
    cursor: 'pointer',
  },
  subDrawerListItem: {
    paddingTop: '0.5rem',
    paddingBottom: '0.5rem',
    height: '42px',
    //marginBottom: '0.6rem',
    cursor: 'pointer',
    marginLeft: '0.4rem',
  },
  drawerListText: {
    fontWeight: '400',
    fontSize: '1.3rem',
    marginLeft: '1.1rem',
  },
  subDrawerListText: {
    fontWeight: '400',
    fontSize: '1.1rem',
    marginLeft: '1rem',
  },
  drawerListImg: {
    width: '30px!important',
  },
  subDrawerListImg: {
    color: '#fff',
    width: '26px!important',
    height: '26px!important',
  },
  discordImg: {
    width: '26px!important',
  },
  drawerBetaImg: {
    color: '#fff',
    width: '24.6px!important',
    height: '8.2px!important',
    marginBottom: '1.1rem',
  },
  languageContainer: {
    cursor: 'pointer',
    display: 'flex',
    margin: '30px',
    alignItems: 'center',
  },
  selectedLanguage: {
    marginLeft: '0.7rem',
    fontWeight: '400',
  },
  drawerLogin: {
    cursor: 'pointer',
    display: 'flex',
    margin: '30px',
    alignItems: 'center',
  },
  drawerLoginText: {
    fontWeight: '500',
    marginLeft: '1rem',
  },
  expandIcon: {
    marginLeft: 'rem',
    marginRight: '0rem',
    fontSize: '30px',
  },
}));

export { useStyles };
