import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
  waveformContainer: {
    width: '100%',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  waveform: {
    width: '85%',
    paddingRight: '1rem',
  },
  volumePopover: {
    '& .MuiPopover-paper': {
      borderRadius: '0.5rem',
      backgroundColor: 'transparent',
      overflow: 'visible',
    },
  },
  volumeSliderContainer: {
    height: 150,
    padding: '1.2rem 0.4rem',
    backgroundColor: '#141414',
    borderRadius: '0.5rem',
  },
  volumeSlider: {
    height: '100%',
    color: '#CAFF73',
    height: 5,
    padding: '15px 0',
    '& .MuiSlider-thumb': {
      height: 16,
      width: 16,
      backgroundColor: '#fff',
      boxShadow: '0 0 2px 0px rgba(0, 0, 0, 0.1)',
      '&:focus, &:hover, &.Mui-active': {
        boxShadow: '0px 0px 3px 1px rgba(0, 0, 0, 0.1)',
        '@media (hover: none)': {
          boxShadow: '0 3px 1px rgba(0,0,0,0.1),0 4px 8px rgba(0,0,0,0.13),0 0 0 1px rgba(0,0,0,0.02)',
        },
      },
      '&:before': {
        boxShadow:
          '0px 0px 1px 0px rgba(0,0,0,0.2), 0px 0px 0px 0px rgba(0,0,0,0.14), 0px 0px 1px 0px rgba(0,0,0,0.12)',
      },
    },
    '& .MuiSlider-track': {
      border: 'none',
      color: '#CAFF73',
    },
    '& .MuiSlider-rail': {
      opacity: 0.5,
      boxShadow: 'inset 0px 0px 4px -2px #000',
      backgroundColor: '#818181',
    },
  },
}));

export { useStyles };
