import React, { useEffect, useState } from 'react';
import Modal from '@material-ui/core/Modal';
import { useDispatch } from 'react-redux';
import { useStyles } from './LanguageModal.styles';
import { appActions } from '../../actions/app.actions';
import { useTranslation } from 'react-i18next';

export default function LanguageModal(props) {
  const { i18n } = useTranslation();
  const dispatch = useDispatch();
  const classes = useStyles();
  const { open } = props;
  const [fontStyle, setFontStyle] = useState('TheJamsil');

  useEffect(() => {
    const defaultLanguage = localStorage.getItem('selectedLanguage') || navigator.language.split('-')[0];
    const defaultFont = defaultLanguage === 'ja' ? 'MPlus1' : 'TheJamsil';
    setFontStyle(defaultFont);
  }, []);

  const selectLanguage = lang => {
    i18n.changeLanguage(lang);
    localStorage.setItem('selectedLanguage', lang);
    dispatch(appActions.closeLanguageModal());
  };

  return (
    <Modal className={fontStyle} open={!!open} onClose={() => dispatch(appActions.closeLanguageModal())}>
      <div className={classes.container}>
        <div className={`${classes.languageButton} TheJamsil`} onClick={() => selectLanguage('en')}>
          English
        </div>
        <div className={`${classes.languageButton} TheJamsil`} onClick={() => selectLanguage('ko')}>
          한국어
        </div>
        <div className={`${classes.languageButton} TheJamsil`} onClick={() => selectLanguage('th')}>
          ภาษาไทย
        </div>
        <div className={`${classes.languageButton} MPlus1`} onClick={() => selectLanguage('ja')}>
          日本語
        </div>
      </div>
    </Modal>
  );
}
