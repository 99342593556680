import React, { useEffect, useState } from 'react';
import Modal from '@material-ui/core/Modal';
import LogoutIcon from '@mui/icons-material/Logout.js';
import { useSelector, useDispatch } from 'react-redux';
import { useStyles } from './LoginModal.styles.js';
import { appActions } from '../../actions/app.actions';
import { userActions } from '../../actions/user.actions';
import GoogleButton from 'react-google-button';
import { useNavigate } from 'react-router-dom';

import kakaoLoginButton from '../../img/kakaoLoginButton.png';
import { backendBaseUrl } from '../../constants/app.constants.js';
import { logout } from '../../services/page.services.js';
import { getCurrency } from '../../utils/page.utils.js';

export default function LoginModal(props) {
  const loggedIn = useSelector(state => state.user?.loggedIn);
  const dispatch = useDispatch();
  const classes = useStyles();
  const navigate = useNavigate();
  const [fontStyle, setFontStyle] = useState('TheJamsil');
  const [currency, setCurrency] = useState();

  useEffect(() => {
    const defaultLanguage = localStorage.getItem('selectedLanguage') || navigator.language.split('-')[0];
    const defaultFont = defaultLanguage === 'ja' ? 'MPlus1' : 'TheJamsil';
    setFontStyle(defaultFont);

    (async () => {
      const userCurrency = await getCurrency();
      setCurrency(userCurrency);
    })();
  }, []);

  useEffect(() => {
    if (loggedIn) {
      const preLoginRoute = sessionStorage.getItem('preLoginRoute');
      if (preLoginRoute) {
        navigate(preLoginRoute);
        sessionStorage.removeItem('preLoginRoute');
      }
    }
  }, [loggedIn]);

  return (
    <Modal className={fontStyle} open={!!props.open} onClose={() => dispatch(appActions.closeLoginModal())}>
      <div className={classes.buttonContainer}>
        {!loggedIn ? (
          <>
            <img
              src={kakaoLoginButton}
              className={classes.kakaoLoginButton}
              alt="kakao login"
              onClick={() => window.location.replace(`${backendBaseUrl}/auth/kakao?loc=${currency}`)}
            />
            <GoogleButton
              className={classes.googleLoginButton}
              label="Continue with Google"
              onClick={() => window.location.replace(`${backendBaseUrl}/auth/google?loc=${currency}`)}
            />
          </>
        ) : (
          <div
            className={classes.logoutButton}
            onClick={async () => {
              try {
                await logout();
              } catch (e) {}
              dispatch(userActions.logout());
              dispatch(appActions.closeLoginModal());
              navigate('/');
            }}
          >
            <LogoutIcon />
            Log out
          </div>
        )}
      </div>
    </Modal>
  );
}
