const subscriptionItems = (t, currency) => {
  const items = t('subscriptionItems', { returnObjects: true });
  return items.map(elem => {
    return {
      name: elem.name,
      model: elem.model,
      price: elem.price[currency],
      features: elem.features,
    };
  });
};
export default subscriptionItems;
