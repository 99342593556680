import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import { AppBar, Toolbar, CircularProgress, MenuItem, Menu } from '@material-ui/core';
import { Menu as MenuIcon } from '@material-ui/icons';
import AccountBox from '@mui/icons-material/AccountBox';
import LogoutIcon from '@mui/icons-material/Logout';
import LanguageIcon from '@mui/icons-material/Language';
import ExpandIcon from '@mui/icons-material/ExpandMore';

import Drawer from './ResponsiveDrawer';
import { useStyles } from './AppBarAndDrawer.styles.js';

import logo from '../../../img/textLogo.png';

import { useSelector, useDispatch } from 'react-redux';
import { userActions } from '../../../actions/user.actions.js';
import { appActions } from '../../../actions/app.actions.js';
import { getFormattedLanguage } from '../../../utils/page.utils';
import { Link } from 'react-router-dom';

function ResponsiveDrawer() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const classes = useStyles();
  const { t } = useTranslation();
  const user = useSelector(state => state.user);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [mobileOpen, setMobileOpen] = React.useState(false);
  const [windowSize, setWindowSize] = useState([window.innerWidth, window.innerHeight]);

  useEffect(() => {
    const { innerHeight, innerWidth } = window;
    const windowResize = () => setWindowSize([innerWidth, innerHeight]);
    window.addEventListener('resize', windowResize);
    return () => window.removeEventListener('resize', windowResize);
  }, []);

  const formattedLanguage = getFormattedLanguage();
  const toggeleDrawer = () => setMobileOpen(!mobileOpen);
  const handleMenu = event => setAnchorEl(event.currentTarget);
  const handleClose = () => setAnchorEl(null);
  const handleLogout = () => {
    dispatch(userActions.logout());
    navigate('/');
  };
  const openLanguageModal = () => dispatch(appActions.openLanguageModal());

  return (
    <div>
      <AppBar className={classes.appBar}>
        <Toolbar>
          <MenuIcon onClick={toggeleDrawer} className={classes.menuButton} />
          <Link to={'/'}>
            <img className={classes.logo} src={logo} alt="Logo" />
          </Link>
          <div className={classes.space}></div>
          <div className={classes.languageContainer} onClick={openLanguageModal}>
            <LanguageIcon />
            <div className={classes.selectedLanguage}>{formattedLanguage}</div>
            <ExpandIcon />
          </div>
          {user.loading && <CircularProgress />}
          {!user.loading &&
            (user.loggedIn ? (
              <div>
                <div className={classes.userNameContainer} onClick={handleMenu}>
                  <AccountBox />
                  <div className={classes.userName}>{user.name.toUpperCase()}</div>
                  <div style={{ display: 'none' }} id="user-id">
                    {user.id}
                  </div>
                </div>
                <Menu
                  anchorReference="anchorPosition"
                  anchorPosition={{ top: 65, left: windowSize[0] - 100 }}
                  anchorEl={anchorEl}
                  keepMounted
                  open={Boolean(anchorEl)}
                  onClose={handleClose}
                  sx={{ top: '65px', display: { xs: 'block', md: 'none' } }}
                >
                  <MenuItem onClick={handleLogout}>
                    <LogoutIcon />
                    Logout
                  </MenuItem>
                </Menu>
              </div>
            ) : (
              <div className={classes.userNameContainer} onClick={() => dispatch(appActions.openLoginModal())}>
                <AccountBox />
                <span className={classes.headerLoginText}>{t('signIn')}</span>
              </div>
            ))}
        </Toolbar>
      </AppBar>
      <Drawer open={mobileOpen} handleDrawerToggle={toggeleDrawer} />
    </div>
  );
}

export default ResponsiveDrawer;
