import React, { useState, useEffect } from 'react';
import moment from 'moment';
import _ from 'lodash';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

import { Grid, Card, ButtonGroup, Button } from '@mui/material';
import { CheckCircle, InfoOutlined } from '@mui/icons-material';

import HtmlTooltip from '../HtmlTooltip';

import tooltipImg from '../../img/tooltip.png';
import popularPurchaseIcon from '../../img/crown_3.png';
import { useStyles } from './SubscriptionPage.styles';
import getSubscriptionItems from './subscriptionItems.js';
import getOnetimeItems from './ontimeItems';
import {
  triggerStripeSession,
  triggerStripeCustomerPortalSession,
  triggerOnetimeStripePortalSession,
  fetchPurchases,
} from '../../services/page.services';
import { appActions } from '../../actions/app.actions.js';
import { checkValidLoginStatus } from '../../utils/user.utils';
import { getCurrency } from '../../utils/page.utils.js';

import * as PortOne from '@portone/browser-sdk/v2';

function SubscriptionPage() {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const user = useSelector(state => state.user);
  const userId = user.id;
  const [currency, setCurrency] = useState(null);
  const subscriptionItems = currency ? getSubscriptionItems(t, user.currency || currency) : [];
  const onetimeItems = currency ? getOnetimeItems(t, user.currency || currency) : [];
  const subscriptionType = user?.subscription?.type;
  const isCustom = subscriptionType === 'CUSTOM';
  const subscriptionStatus = user?.subscription?.status;
  const classes = useStyles();

  const [purchaseType, setPurchasetype] = useState('subscription');
  const [purchaseHistory, setPurchaseHistory] = useState([]);

  useEffect(() => {
    (async () => {
      const currencyToSet = await getCurrency();
      setCurrency(currencyToSet);
    })();
  }, []);

  useEffect(() => {
    if (userId) {
      (async () => {
        await initData();
      })();
    }
  }, [userId, purchaseType]);

  const initData = async () => {
    if (purchaseType === 'onetime') {
      let purchases = await fetchPurchases();
      setPurchaseHistory(purchases);
    }
  };

  const cardStyle = {
    padding: '1.5rem 1rem',
    backgroundColor: '#18181b',
    color: '#fff',
    fontWeight: '400',
    borderRadius: '0.5rem',
    height: '30.5rem',
    minWidth: '19rem',
    margin: '0.01rem',
  };

  const oneTimeCardStyle = {
    padding: '1.5rem 1rem',
    backgroundColor: '#18181b',
    color: '#fff',
    fontWeight: '400',
    borderRadius: '0.5rem',
    minWidth: '19rem',
  };

  const selectedStyle = { border: '0.1rem solid #D1FE75' };
  const pastDueStyle = { border: '0.1rem solid #e34c4c' };
  const loggedInStyle = { height: '34.5rem' };
  const featureIcon = { fontSize: 'medium', color: '#71717A' };
  const infoIcon = { fontSize: '1.5rem', marginLeft: '0.5rem', color: '#71717A' };

  const onClick = async model => {
    if (user?.email === 'test.sorisori@gmail.com') {
      await PortOne.requestPayment({
        storeId: 'store-94887967-6f19-478d-b8dd-5320faafae96',
        channelKey: 'channel-key-33e26923-2e0e-4bee-8e33-48efe4889d74',
        paymentId: `payment-${crypto.randomUUID()}`,
        orderName: `${model} 구독`,
        totalAmount: 12000,
        currency: 'CURRENCY_KRW',
        payMethod: 'EASY_PAY',
      });
    } else {
      if (checkValidLoginStatus(user.id, dispatch)) {
        if (user.subscription?.paymentService === 'iap_ios') {
          dispatch(appActions.openMessageModal(t('subscriptionTab.mobilePaymentRequired')));
          return;
        }
        if (user.subscription?.id) {
          await triggerStripeCustomerPortalSession();
        } else {
          await triggerStripeSession(model);
        }
      }
    }
  };

  const onClickOnetimePurchase = async type => {
    if (user?.email === 'test.sorisori@gmail.com') {
      await PortOne.requestPayment({
        storeId: 'store-94887967-6f19-478d-b8dd-5320faafae96',
        channelKey: 'channel-key-33e26923-2e0e-4bee-8e33-48efe4889d74',
        paymentId: `payment-${crypto.randomUUID()}`,
        orderName: `${type} 결제`,
        totalAmount: 5000,
        currency: 'CURRENCY_KRW',
        payMethod: 'EASY_PAY',
      });
    } else {
      if (checkValidLoginStatus(user.id, dispatch)) {
        triggerOnetimeStripePortalSession(type);
      }
    }
  };

  const selectedLanguage = localStorage.getItem('selectedLanguage');
  const useThaiStyle = selectedLanguage === 'th';
  return (
    <Grid container className={classes.container} spacing={3}>
      <Grid style={{ padding: 0 }} item xs={12} sm={12} md={12}>
        <div className={classes.pageHeaderContainer}>
          <div className={classes.pageHeader}>
            {purchaseType === 'subscription' && t('subscriptionTab.tabs.subscription.pageTitleTwo')}
            {purchaseType === 'onetime' && t('subscriptionTab.tabs.onetime.pageTitleTwo')}
          </div>
        </div>
      </Grid>
      <Grid style={{ display: 'flex', justifyContent: 'center' }} item xs={12} sm={12} md={12}>
        <ButtonGroup className={classes.purchaseTypeButtonContainer}>
          <Button
            className={`${classes.purchaseTypeButton} ${purchaseType == 'subscription' ? classes.selected : ''}`}
            onClick={() => setPurchasetype('subscription')}
          >
            {t('subscriptionTab.tabs.subscription.title')}
          </Button>
          <Button
            className={`${classes.purchaseTypeButton} ${purchaseType == 'onetime' ? classes.selected : ''}`}
            onClick={() => setPurchasetype('onetime')}
          >
            {t('subscriptionTab.tabs.onetime.title')}
          </Button>
        </ButtonGroup>
      </Grid>
      {purchaseType === 'onetime' && (
        <>
          {onetimeItems.map(({ name, price, type }) => (
            <Grid item className={classes.cardGrid} xs={12} sm={6} md={3}>
              <Card sx={oneTimeCardStyle}>
                <div className={classes.cardHeaderContainer}>
                  <div className={classes.cardHeader}>{name}</div>
                </div>
                <div className={classes.onetimeFee}>{price}</div>
                <div className={classes.onetimeCardButton} onClick={() => onClickOnetimePurchase(type)}>
                  {t('subscriptionTab.tabs.onetime.purchase')}
                </div>
              </Card>
            </Grid>
          ))}
          <Grid item xs={12} sm={12} md={12}>
            <div className={`${classes.separater} ${classes.noMargin}`} />
          </Grid>
          <Grid item xs={12} sm={12} md={12}>
            <div className={classes.stepHeader}>{t('subscriptionTab.tabs.onetime.history')}</div>
          </Grid>
          <Grid item xs={12} sm={12} md={12}>
            {purchaseHistory.map(({ type, quantity, createdAt }) => {
              return (
                <div className={classes.card}>
                  <div className={classes.cardLabel}>
                    {_.find(onetimeItems, { type }).name} ({quantity})
                  </div>
                  <div className={classes.cardTimestamp}>{moment(createdAt).format('MM/DD/YY hh:mm A')}</div>
                </div>
              );
            })}
          </Grid>
        </>
      )}
      {purchaseType === 'subscription' &&
        subscriptionItems.map(({ name, model, price, discountedPrice, discountRate, period, features }) => (
          <Grid item className={classes.cardGrid} xs={12} sm={6} md={3}>
            <Card
              className={
                (!subscriptionType || subscriptionType === 'FREE') && model === 'PRO'
                  ? classes.popularSubscription
                  : classes.normalSubscription
              }
            >
              {(!subscriptionType || subscriptionType === 'FREE') && model === 'PRO' ? (
                <div className={classes.cardSubscriptionHeader}>
                  <img className={classes.pupularPurchaseIcon} src={popularPurchaseIcon} alt="popular-icon" />
                  {t('subscriptionTab.popular')}
                </div>
              ) : (
                <div className={classes.cardSubscriptionHeader}></div>
              )}
              <div
                style={{
                  ...cardStyle,
                  ...(user.loggedIn ? loggedInStyle : {}),
                  ...(model === subscriptionType && subscriptionStatus !== 'past_due' ? selectedStyle : {}),
                  ...(model === subscriptionType && subscriptionStatus === 'past_due' ? pastDueStyle : {}),
                }}
              >
                <div className={classes.cardHeaderContainer}>
                  <div className={classes.cardHeader}>{name}</div>
                  <div className={classes.cardHeaderBadgeContainer}>
                    {!!discountRate && <div className={classes.promoBadge}>{discountRate}</div>}
                    {model === subscriptionType && subscriptionStatus !== 'past_due' && (
                      <div className={classes.cardHeaderCurrent}>{t('subscriptionTab.tabs.subscription.status')}</div>
                    )}
                    {model === subscriptionType && subscriptionStatus === 'past_due' && (
                      <div className={classes.cardHeaderPastDue}>{t('subscriptionTab.tabs.subscription.pastDue')}</div>
                    )}
                  </div>
                </div>
                <div className={classes.cardFee}>
                  <span className={!!discountedPrice ? classes.strikethroughPrice : undefined}>{price}</span>
                  {!!discountedPrice && <span>{discountedPrice}</span>}
                  <span className={classes.cardFeeSubText}>{period}</span>
                </div>
                <div className={classes.divider} />

                {(subscriptionType === model || (!subscriptionType && model === 'FREE')) && (
                  <>
                    <div className={classes.cardButton} onClick={() => onClickOnetimePurchase('INFERENCE')}>
                      {`${t('onetimeItems.inference.name')}: ${t(`onetimeItems.inference.price.${user.currency || currency}`)}`}

                      <HtmlTooltip
                        title={
                          <div className={classes.tooltipContainer}>
                            <div className={classes.tooltipText}>{t('onetimeItems.inference.tooltip')}</div>
                          </div>
                        }
                        onClick={e => e.stopPropagation()}
                      >
                        <InfoOutlined sx={infoIcon} />
                      </HtmlTooltip>
                    </div>
                    <div className={classes.cardButton} onClick={() => onClickOnetimePurchase('TRAINING')}>
                      {`${t('onetimeItems.training.name')}: ${t(`onetimeItems.training.price.${user.currency || currency}`)}`}
                    </div>
                  </>
                )}
                {((!!subscriptionType && subscriptionType !== model) || (!subscriptionType && model !== 'FREE')) && (
                  <div
                    className={`${classes.cardButton} ${isCustom && classes.cardButtonDisabled}`}
                    onClick={() => !isCustom && onClick(model)}
                  >
                    {!subscriptionType || subscriptionType === 'FREE'
                      ? t('subscriptionTab.tabs.subscription.subscribe')
                      : t('subscriptionTab.tabs.subscription.change')}
                  </div>
                )}
                {features.map(({ text, tooltips }) => (
                  <div className={classes.featureContainer}>
                    <CheckCircle sx={featureIcon} />
                    <div className={`${useThaiStyle && classes.featureTextThai} ${classes.featureText} `}>
                      {text}
                      {tooltips && (
                        <HtmlTooltip
                          title={
                            <div className={classes.tooltipContainer}>
                              {tooltips.map(tooltipText => (
                                <div className={classes.tooltipTextContainer}>
                                  <div className={`${classes.tooltipText} ${classes.tooltipTextBullet}`}>&#x2022;</div>
                                  <div className={classes.tooltipText}>{tooltipText}</div>
                                </div>
                              ))}
                            </div>
                          }
                        >
                          <img className={classes.tooltipImg} src={tooltipImg} alt="tooltip-img" />
                        </HtmlTooltip>
                      )}
                    </div>
                  </div>
                ))}
              </div>
            </Card>
          </Grid>
        ))}
    </Grid>
  );
}

export default SubscriptionPage;
