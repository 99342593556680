import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
  buttonContainer: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column',
    backgroundColor: '#fff',
    padding: '2rem 5rem',
    '&:active': {
      outline: 'none',
    },
    '&:focus': {
      outline: 'none',
    },
  },
  kakaoLoginButton: {
    width: '15rem',
    cursor: 'pointer',
  },
  googleLoginButton: {
    width: '15rem',
    marginTop: '1rem',
  },
  logoutButton: {
    cursor: 'pointer',
    display: 'flex',
    alignItems: 'center',
  },
}));

export { useStyles };
