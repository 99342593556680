import { configureStore } from '@reduxjs/toolkit';
import { userReducer } from './reducers/users.reducer';
import { appReducer } from './reducers/app.reducer';

export default configureStore({
  reducer: {
    user: userReducer,
    app: appReducer,
  },
});
