import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
  container: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column',
    backgroundColor: '#fff',
    padding: '2rem 3rem',
    wordBreak: 'keep-all!important',
    fontSize: '1.2rem',
    fontWeight: '400',
    height: '12rem',
    justifyContent: 'center',
    borderRadius: '0.3rem',
    '&:active': {
      outline: 'none',
    },
    '&:focus': {
      outline: 'none',
    },
  },
  languageButton: {
    display: 'flex',
    alignItems: 'center',
    height: '2.5rem',
    cursor: 'pointer',
  },
}));

export { useStyles };
