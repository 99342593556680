import Modal from '@material-ui/core/Modal';
import { useStyles } from './TTSSettingModal.styles.js';

import React, { useRef, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { appActions } from '../../actions/app.actions.js';

import Grid from '@material-ui/core/Grid';
import Slider from '@material-ui/core/Slider';

import { checkValidLoginStatus } from '../../utils/user.utils';

import HtmlTooltip from '../HtmlTooltip';
import tooltipImg from '../../img/tooltip.png';

export default function TTSSettingModal(props) {
  const { t } = useTranslation();
  const classes = useStyles();

  const { open, loading } = props;
  const [variability, setVariability] = useState(props.variability);
  const [similarity, setSimilarity] = useState(props.similarity);

  const handleSliderChange = setValue => (event, newValue) => {
    setValue(newValue);
  };

  //Run this whenever userId(whenver the page loads for the first time), query, or alignment change
  const [fontStyle, setFontStyle] = useState('TheJamsil');

  useEffect(() => {
    const defaultLanguage = localStorage.getItem('selectedLanguage') || navigator.language.split('-')[0];
    const defaultFont = defaultLanguage === 'ja' ? 'MPlus1' : 'TheJamsil';
    setFontStyle(defaultFont);
  }, []);

  //check if selected language is korean or not
  const translator = option => {
    if (t('languageDetector') === 'ko') {
      return option.korean;
    } else {
      return option.name;
    }
  };

  return (
    <Modal
      open={!!open}
      onClose={() => {
        props.onSettingModalClose();
      }}
      className={`${fontStyle}`}
    >
      <div className={classes.container}>
        <Grid item xs={12} sm={12} md={12}>
          <Grid container direction="column" className={classes.sliderContainer}>
            <Grid container xs={12} sm={12} md={12} direction="row">
              <div container item className={classes.sliderName}>
                {t('ttsInferenceTab.modal.variability')}
              </div>
            </Grid>
            <Slider
              className={classes.slider}
              defaultValue={30}
              value={variability}
              onChange={(e, val) => {
                setVariability(val);
                props.setVariability(val);
              }}
              valueLabelDisplay="on"
              valueLabelFormat={value => <div>{value}%</div>}
              step={1}
              min={0}
              max={100}
            />
            <Grid container justifyContent="space-between" xs={12} sm={12} md={12}>
              <div className={classes.sliderStart}>{t('ttsInferenceTab.modal.stable')}</div>
              <div className={classes.sliderEnd}>{t('ttsInferenceTab.modal.variable')}</div>
            </Grid>
          </Grid>
        </Grid>

        <Grid item xs={12} sm={12} md={12}>
          <Grid container direction="column" className={classes.sliderContainer}>
            <Grid container xs={12} sm={12} md={12} direction="row">
              <div container item className={classes.sliderName}>
                {t('ttsInferenceTab.modal.similarity')}
              </div>
            </Grid>
            <Slider
              className={classes.slider}
              defaultValue={50}
              value={similarity}
              onChange={(e, val) => {
                setSimilarity(val);
                props.setSimilarity(val);
              }}
              valueLabelDisplay="on"
              valueLabelFormat={value => <div>{value}%</div>}
              step={1}
              min={0}
              max={100}
            />
            <Grid container justifyContent="space-between" xs={12} sm={12} md={12}>
              <div className={classes.sliderStart}>{t('ttsInferenceTab.modal.low')}</div>
              <div className={classes.sliderEnd}>{t('ttsInferenceTab.modal.high')}</div>
            </Grid>
          </Grid>
        </Grid>
      </div>
    </Modal>
  );
}
