import React, { useEffect, useState } from 'react';
import Modal from '@material-ui/core/Modal';
import { useStyles } from './FreeVocalExtractorMessageModal.styles';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import textLogoWhite from '../../img/textLogo.png';
import { useSelector } from 'react-redux';

export default function FreeVocalExtractorMessageModal(props) {
  const { t } = useTranslation();
  const classes = useStyles();
  const navigate = useNavigate();
  const [fontStyle, setFontStyle] = useState('TheJamsil');
  const user = useSelector(state => state.user);
  const userType = user?.subscription?.type;

  useEffect(() => {
    const defaultLanguage = localStorage.getItem('selectedLanguage') || navigator.language.split('-')[0];
    const defaultFont = defaultLanguage === 'ja' ? 'MPlus1' : 'TheJamsil';
    setFontStyle(defaultFont);
  }, []);

  const getMessages = () => {
    switch (userType) {
      case 'FREE':
        return t('freeVocalExtractorMessageModal.free.message', { returnObjects: true });
      case 'BASIC':
        return t('freeVocalExtractorMessageModal.basic.message', { returnObjects: true });
      default:
        return [];
    }
  };

  const messages = getMessages();

  return (
    <Modal className={fontStyle} open={!!props.open} onClose={() => props.setOpenFreeVocalExtractorModal(false)}>
      <div className={classes.messageContainer}>
        <img className={classes.sorisoriAIImg} src={textLogoWhite} alt="sorisoriAI" />
        <div className={classes.messageOneContainer}>
          {messages.map(message => (
            <div className={classes.messageOne}>{message}</div>
          ))}
        </div>
        <div className={classes.button} onClick={() => navigate('/subscription')}>
          {t('freeVocalExtractorMessageModal.button')}
        </div>
      </div>
    </Modal>
  );
}
