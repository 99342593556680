import React, { useRef, useEffect, useState, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { useStyles } from './Community.styles';

import ReactTimeAgo from 'react-time-ago';
import ReactJkMusicPlayer from 'react-jinke-music-player';
import moment from 'moment';
import _ from 'lodash';

import { ButtonGroup, Button } from '@mui/material';
import Grid from '@material-ui/core/Grid';
import AudioFileRoundedIcon from '@mui/icons-material/AudioFileRounded';
import PlayCircleOutlineIcon from '@mui/icons-material/PlayCircleOutline';
import PauseCircleOutlineIcon from '@mui/icons-material/PauseCircleOutline';
import FavoriteBorderRoundedIcon from '@mui/icons-material/FavoriteBorderRounded';
import FavoriteIcon from '@mui/icons-material/Favorite';
import Autocomplete from '@mui/material/Autocomplete';
import TextField from '@mui/material/TextField';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';

import { checkValidLoginStatus } from '../../utils/user.utils';
import CommunityUploadModal from '../layout/CommunityUploadModal';
import {
  updateTrainingById,
  updateCommunityItemById,
  updateInferenceById,
  updateVocalExtractById,
  checkVocalExtractCompletion,
  fetchInferencesChunk,
  fetchTrainingsChunk,
  fetchVocalExtractsChunk,
  fetchCommunityItems,
  fetchPagedCommunityItems,
  likeCommunityItem,
  dislikeCommunityItem,
  getCommunitySampleSignedUrl,
  fetchAllTags,
  fetchTaggedCommunityItems,
} from '../../services/page.services';

import coverImg from '../../img/cover.png';
import InfiniteScroll from 'react-infinite-scroll-component';
import { appActions } from '../../actions/app.actions.js';

function Community() {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const user = useSelector(state => state.user);
  const userId = user?.id ?? null;

  //initData
  const [data, setData] = useState([]);
  const [filteredData, setFilteredData] = useState([]);

  //search
  const [communityQuery, setCommunityQuery] = useState('');

  //community upload modal
  const [communityUploadProps, setCommunityUploadProps] = useState({});

  //play
  const [selectedAudio, setSelectedAudio] = React.useState();
  const [playing, setPlaying] = useState(false);
  const [selectedCommunityId, setSelectedCommunityId] = useState('');

  //tags and sorting
  const [selectedSortingMethod, setSelectedSortingMethod] = React.useState('creations');
  const [allTags, setAllTags] = useState([]);
  const [selectedTags, setSelectedTags] = useState([]);
  const [defaultTag, setDefaultTag] = useState([]);

  //pagination
  const chunk = 50;
  const communityChunk = 150;
  const [chunkPage, setChunkPage] = useState(0);
  const [length, setLength] = useState(0);
  const controllerRef = useRef(null);
  //const [page, setPage] = useState(1);
  const [paginationInfo, setPaginationInfo] = useState({});

  useEffect(() => {
    const fetchCountryTags = async () => {
      try {
        const fetchedTags = await fetchAllTags();
        setAllTags(fetchedTags);

        const languageTagMap = {
          ko: 'South Korea',
          th: 'Thailand',
          ja: 'Japan',
          en: 'United States',
        };

        const defaultTagName = languageTagMap[t('languageDetector')];
        if (defaultTagName) {
          const dT = fetchedTags.find(tag => tag.name === defaultTagName);
          if (dT) {
            setDefaultTag(dT);
            setSelectedTags([dT]);
          }
        }
      } catch (err) {
        dispatch(appActions.openMessageModal(t('historyTab.modal.error'), false, true));
      }
    };
    fetchCountryTags();
  }, [t('languageDetector')]);

  useEffect(() => {
    if (allTags?.length > 0) {
      handleSearch();
    }
  }, [userId, communityQuery, selectedTags, selectedSortingMethod, allTags]);

  //add event listened to be triggered whenever the played song ends
  useEffect(() => {
    if (selectedAudio) {
      selectedAudio.addEventListener('ended', () => {
        setSelectedCommunityId('');
        setPlaying(false);
      });
    }
    return () => {
      if (selectedAudio) {
        selectedAudio.pause();
        selectedAudio.removeEventListener('ended', () => {
          setSelectedCommunityId('');
          setPlaying(false);
        });
      }
    };
  }, [selectedAudio]);

  useEffect(() => {
    if (selectedAudio) {
      playing ? selectedAudio.play() : selectedAudio.pause();
    }
  }, [playing]);

  const getBlobFromS3Url = async url => {
    const res = await fetch(url);
    return res.blob();
  };

  const initData = async scroll => {
    if (controllerRef.current) {
      controllerRef.current.abort();
    }
    controllerRef.current = new AbortController();
    const signal = controllerRef.current.signal;

    try {
      if (!allTags.length) {
        const fetchedAllTags = await fetchAllTags();
        setAllTags(fetchedAllTags);
      }
      const communityItems = await fetchPagedCommunityItems(
        1,
        communityChunk,
        communityQuery,
        selectedTags,
        selectedSortingMethod,
        userId,
        { signal }
      );
      setData(communityItems.rows);
      setFilteredData(communityItems.rows);
      setPaginationInfo(communityItems.pagination);
    } catch (err) {
      if (err.name != 'CanceledError') {
        dispatch(appActions.openMessageModal(t('historyTab.modal.error'), false, true));
      }
    }
  };

  const handleSearch = _.debounce(initData, 300);

  const fetchMoreData = async () => {
    if (controllerRef.current) {
      controllerRef.current.abort();
    }
    controllerRef.current = new AbortController();
    const signal = controllerRef.current.signal;

    try {
      //setPage(page + 1);

      const communityItems = await fetchPagedCommunityItems(
        paginationInfo.next,
        communityChunk,
        communityQuery,
        selectedTags,
        selectedSortingMethod,
        userId,
        { signal }
      );
      setData([...data, ...communityItems.rows]);
      setFilteredData([...data, ...communityItems.rows]);
      setPaginationInfo(communityItems.pagination);
    } catch (err) {
      if (err.name != 'CanceledError') {
        dispatch(appActions.openMessageModal(t('historyTab.modal.error'), false, true));
      }
    }
  };

  /*

  const sortingFunction = (a, b) => {
    if (selectedSortingMethod === 'creations') {
      return Number(b.numberUsed) - Number(a.numberUsed);
    } else if (selectedSortingMethod === 'label') {
      const aLabel = a.label ? a.label : '';
      const bLabel = b.label ? b.label : '';
      return aLabel.localeCompare(bLabel);
    } else if (selectedSortingMethod === 'likes') {
      return Number(b.likedNumber) - Number(a.likedNumber);
    } else {
      return moment(b.createdAt).diff(moment(a.createdAt));
    }
  };
  */

  const translator = option => {
    if (t('languageDetector') === 'ko') {
      return option.korean;
    } else {
      return option.name;
    }
  };

  const handleSortChange = async event => {
    setSelectedSortingMethod(event.target.value);
  };

  const handleLike = async (event, communityItemId) => {
    event.stopPropagation();
    if (checkValidLoginStatus(user.id, dispatch)) {
      likeCommunityItem(communityItemId);
      const copiedData = data.map(obj => {
        if (obj.id === communityItemId) {
          return {
            ...obj,
            likedNumber: Number(obj.likedNumber) + 1,
            liked: true,
          };
        }
        return obj;
      });
      const copiedFilteredData = filteredData.map(obj => {
        if (obj.id === communityItemId) {
          return {
            ...obj,
            likedNumber: Number(obj.likedNumber) + 1,
            liked: true,
          };
        }
        return obj;
      });

      setData(copiedData);
      setFilteredData(copiedFilteredData);
    }
  };

  const handleDislike = async (event, communityItemId) => {
    event.stopPropagation();
    dislikeCommunityItem(communityItemId);
    const copiedData = data.map(obj => {
      if (obj.id === communityItemId) {
        let newObj = { ...obj };
        newObj.likedNumber = Number(obj.likedNumber) - 1;
        newObj.liked = false;

        return newObj;
      }
      return obj;
    });
    const copiedFilteredData = filteredData.map(obj => {
      if (obj.id === communityItemId) {
        let newObj = { ...obj };
        newObj.likedNumber = Number(obj.likedNumber) - 1;
        newObj.liked = false;

        return newObj;
        //return { ...obj, likedNumber: obj.likedNumber - 1 };
      }
      return obj;
    });

    setData(copiedData);
    setFilteredData(copiedFilteredData);
  };

  const handlePlay = async (communityItemId, label) => {
    if (selectedCommunityId === communityItemId) {
      setPlaying(true);
    } else {
      setPlaying(false);
      const sampleVoiceUrl = await getCommunitySampleSignedUrl(communityItemId);
      const sampleVoiceBlob = await getBlobFromS3Url(sampleVoiceUrl);
      if (sampleVoiceBlob.size > 2000) {
        const audio = await new Audio(URL.createObjectURL(sampleVoiceBlob.slice(0, sampleVoiceBlob.size, `audio/mp3`)));
        setSelectedAudio(audio);
        setPlaying(true);
        setSelectedCommunityId(communityItemId);
      }
    }
  };

  const handlePlay2 = _.debounce(handlePlay, 500, { leading: true });

  const handlePause = async (communityItemId, label) => {
    setPlaying(false);
  };

  const handleShare = async () => {
    setCommunityUploadProps({
      open: true,
      onCommunityModalClose: () => {
        setCommunityUploadProps({ open: false });
      },
    });
  };
  const classes = useStyles();

  return (
    <>
      <CommunityUploadModal {...communityUploadProps} />
      <div className={classes.pageTitle}>{t('communityTab.title')}</div>
      <div className={classes.separater} />

      <Grid className={classes.searchButtonContainer} container>
        <Grid item xs={7} sm={7} md={7}>
          <input
            className={classes.searchbar}
            placeholder={t('historyTab.searchbar')}
            value={communityQuery}
            onChange={newValue => setCommunityQuery(newValue.target.value)}
          />
        </Grid>
        <Grid container item xs={4} sm={4} md={4} display="flex" direction="row-reverse">
          <div className={classes.button} justify="center" onClick={handleShare}>
            {t('communityTab.button.share')}
          </div>
        </Grid>
      </Grid>
      <Grid className={classes.filterSortContainer} container>
        <Grid item xs={7} sm={7} md={7}>
          <Autocomplete
            key={defaultTag ? defaultTag.id : 'no-default-tag'}
            multiple
            limitTags={2}
            id="multiple-limit-tags"
            size="small"
            sx={{
              borderRadius: '0.2rem',
              '& .MuiOutlinedInput-notchedOutline': {
                border: 'none',
              },
              '&:hover .MuiOutlinedInput-notchedOutline': {
                border: 'none',
              },
              '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                border: 'none',
              },
            }}
            className={classes.tagAutoComplete}
            options={allTags}
            getOptionLabel={option => translator(option)}
            defaultValue={defaultTag ? [defaultTag] : []}
            groupBy={option => option.type}
            onChange={(event, newValues) => {
              setSelectedTags(newValues);
            }}
            renderInput={params => <TextField {...params} placeholder={t('communityTab.tags')} />}
          />
        </Grid>
        <Grid container item xs={4} sm={4} md={4} display="flex" justifyContent="flex-end">
          <FormControl
            size="small"
            className={classes.sortSelect}
            sx={theme => ({
              width: '7rem',
              [theme.breakpoints.down(1134)]: {
                width: '6.3rem',
              },
              borderRadius: '0.2rem',
              fontSize: '1rem',
              '& .MuiOutlinedInput-notchedOutline': {
                border: 'none',
              },
              '&:hover .MuiOutlinedInput-notchedOutline': {
                border: 'none',
              },
              '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                border: 'none',
              },
              fontSize: '0.7rem',
            })}
            //sx={{ width: { sm: "6.3rem", md: "7rem" } }}
          >
            <Select
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              value={selectedSortingMethod}
              devaultValue={'date'}
              label="test"
              onChange={handleSortChange}
            >
              <MenuItem value={'recent'}>{t('communityTab.sortOptions.0')}</MenuItem>
              <MenuItem value={'likes'}>{t('communityTab.sortOptions.1')}</MenuItem>
              <MenuItem value={'creations'}>{t('communityTab.sortOptions.2')}</MenuItem>
              <MenuItem value={'label'}>{t('communityTab.sortOptions.3')}</MenuItem>
            </Select>
          </FormControl>
        </Grid>
      </Grid>
      <InfiniteScroll
        className={classes.infiniteScroll}
        dataLength={data.length}
        next={fetchMoreData}
        hasMore={paginationInfo.has_next}
        loader={
          <div className={classes.pagination} key={0}>
            {t('historyTab.pagination.loader')}
          </div>
        }
      >
        <Grid container className={classes.cardGrid}>
          {filteredData.map(
            ({
              id,
              gender,
              label,
              username,
              createdAt,
              status,
              trainingLabel,
              image,
              likedNumber,
              users,
              numberUsed,
              anonymous,
              liked,
            }) => {
              return (
                <Grid
                  className={classes.card}
                  item
                  container
                  key={id}
                  onClick={event => {
                    dispatch(
                      appActions.selectCommunityItem({
                        id,
                        gender,
                        label,
                        username,
                        createdAt,
                        status,
                        trainingLabel,
                        image,
                        likedNumber,
                        users,
                        numberUsed,
                        anonymous,
                      })
                    );
                    navigate('/generate-music');
                  }}
                >
                  <Grid
                    item
                    xs="auto"
                    sm={3}
                    md={3}
                    //style={{ border: "1px solid #fafafa" }}
                  >
                    <img className={classes.coverImg} src={image ? image : coverImg} alt="cover-img" />
                  </Grid>
                  <Grid
                    container
                    className={classes.cardSecondColumn}
                    //style={{ border: "1px solid #fafafa" }}
                    item
                    xs
                    sm={9}
                    md={9}
                  >
                    <Grid container item>
                      <Grid item xs={10} sm={10} md={10}>
                        <div className={classes.cardLabel}>{label}</div>
                      </Grid>
                      <Grid
                        container
                        item
                        className={classes.playButtonGrid}
                        xs={2}
                        sm={2}
                        md={2}
                        //style={{ border: "1px solid #fafafa" }}
                      >
                        {playing && id === selectedCommunityId ? (
                          <PauseCircleOutlineIcon
                            className={classes.playButtonImg}
                            onClick={event => {
                              event.stopPropagation();
                              handlePause(id, label);
                            }}
                          />
                        ) : (
                          <PlayCircleOutlineIcon
                            className={classes.playButtonImg}
                            onClick={event => {
                              event.stopPropagation();
                              handlePlay2(id, label);
                            }}
                          />
                        )}
                      </Grid>
                    </Grid>
                    <Grid container item>
                      {!anonymous && <div className={classes.cardUsername}>{username}</div>}
                    </Grid>
                    <Grid
                      container
                      item
                      style={{
                        maxHeight: '2rem',
                      }}
                    >
                      <Grid
                        className={classes.creationsGrid}
                        container
                        item
                        xs={4}
                        sm={4}
                        md={4}
                        style={{
                          maxHeight: '1.1rem',
                        }}
                      >
                        <div className={classes.numberPlayed}>{numberUsed}</div>
                        <div className={classes.creations}>{'creations'}</div>
                      </Grid>
                      <Grid
                        container
                        item
                        xs={5}
                        sm={5}
                        md={5}
                        style={{
                          alignItems: 'flex-end',
                          maxHeight: '1.1rem',
                        }}
                      >
                        <div className={classes.cardTimestamp}>
                          <ReactTimeAgo date={createdAt} locale="en-US" />
                        </div>
                      </Grid>
                      <Grid
                        container
                        item
                        xs={3}
                        sm={3}
                        md={3}
                        className={classes.cardLikes}
                        style={{
                          maxHeight: '2rem',
                        }}
                      >
                        {!liked && (
                          <FavoriteBorderRoundedIcon
                            className={classes.favoriteImg}
                            onClick={event => handleLike(event, id)}
                            fontSize="inherit"
                          />
                        )}
                        {liked && (
                          <FavoriteIcon
                            className={classes.favoriteFullImg}
                            onClick={event => handleDislike(event, id)}
                            fontSize="inherit"
                          />
                        )}
                        <div className={classes.numberLikes}>{likedNumber}</div>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              );
            }
          )}
        </Grid>
      </InfiniteScroll>
    </>
  );
}
export default Community;
