import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
  pageTitle: {
    [theme.breakpoints.down('sm')]: {
      marginTop: '1.5rem',
    },
    paddingLeft: '1rem',
    fontSize: '2.5rem',
    color: '#fff',
    alignItems: 'center',
    display: 'flex',
  },
  separater: {
    borderBottomWidth: '1px',
    borderBottomColor: '#A4A4A4',
    borderBottomStyle: 'solid',
    margin: '1.5rem 0',
  },
  header: {
    fontSize: '1.5rem',
    marginBottom: '1.5rem',
    color: '#fff',
    fontWeight: '400',
    display: 'flex',
    alignItems: 'center',
  },
  buttonContainer: {
    display: 'flex',
    alignItems: 'center',
    paddingTop: '1.5rem',
  },
  radioButtonLabel: {
    [theme.breakpoints.down('xs')]: {
      fontSize: '0.85rem!important',
    },
    '& .MuiFormControlLabel-label': {
      fontWeight: '300',
      fontSize: '1.1rem !important',
      color: '#fff !important',
    },
    marginRight: '1rem',
    display: 'flex',
    alignItems: 'center',
  },
  checkbox: {
    color: '#fff !important',
  },
  button: {
    [theme.breakpoints.down('xs')]: {
      padding: '0.7rem 1rem',
      fontSize: '1rem',
    },
    backgroundColor: '#CAFF73',
    padding: '0.7rem 2.5rem',
    fontSize: '1.1rem',
    borderRadius: '0.2rem',
    cursor: 'pointer',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    minWidth: '8.5em',
    minHeight: '2.5em',
  },
}));

export { useStyles };
