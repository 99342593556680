import React, { useEffect, useState } from 'react';
import Modal from '@material-ui/core/Modal';
import { useDispatch } from 'react-redux';
import { useStyles } from './MessageModal.styles.js';
import { appActions } from '../../actions/app.actions';
import RefreshIcon from '@mui/icons-material/Refresh';

export default function MessageModal(props) {
  const dispatch = useDispatch();
  const classes = useStyles();
  const [fontStyle, setFontStyle] = useState('TheJamsil');

  useEffect(() => {
    const defaultLanguage = localStorage.getItem('selectedLanguage') || navigator.language.split('-')[0];
    const defaultFont = defaultLanguage === 'ja' ? 'MPlus1' : 'TheJamsil';
    setFontStyle(defaultFont);
  }, []);

  return (
    <Modal
      className={fontStyle}
      open={!!props.open}
      onClose={() => {
        dispatch(appActions.closeMessageModal());
        props.reloadOnClose && window.location.reload();
      }}
    >
      <div className={classes.messageContainer}>
        {props.message}
        {props.showRefreshButton && (
          <RefreshIcon
            fontSize="large"
            className={classes.refreshButton}
            onClick={() => {
              window.location.reload();
            }}
          />
        )}
      </div>
    </Modal>
  );
}
