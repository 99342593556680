import { createTheme } from '@material-ui/core/styles';

const myTheme = createTheme({
  breakpoints: {
    // values: {
    //   xs: 0,
    //   sm: 550,
    //   md: 900,
    //   lg: 1200,
    //   xl: 1536,
    // },
  },
  icon: {
    fill: '#fff',
  },
  overrides: {
    MuiOutlinedInput: {
      root: {
        '&.Mui-focused fieldset': {
          borderColor: '#fff!important',
        },
      },
      input: {
        '&::placeholder': {
          opacity: 1,
        },
        color: '#161616',
      },
    },
    MuiDrawer: {
      paper: {
        zIndex: '1',
      },
    },
    MuiSlider: {
      root: {
        color: '#1e1e1e',
      },
      thumb: {
        color: '#06603A',
      },
      track: {
        color: '#06603A',
      },
    },
    MuiAppBar: {
      colorPrimary: {
        backgroundColor: '#fff',
      },
    },
    MuiSlider: {
      track: {
        color: '#CAFF73',
      },
      rail: {
        color: '#A4A4A4',
      },
      thumb: {
        color: '#CAFF73',
        '& > span > span > span': {
          color: '#1E1E1E',
          fontWeight: '700',
        },
      },
    },
    MuiButton: {
      // Name of the component ⚛️ / style sheet
      contained: {
        // Name of the rule
        backgroundColor: '#000', // Some CSS
        color: '#fff',
        // color: 'red', // Some CSS
      },
      outlined: {
        // Name of the rule
        backgroundColor: '#f0f0f0', // Some CSS
        border: 'none',
        // color: "#fff"
        // color: 'red', // Some CSS
      },
      // containedPrimary: { // Name of the rule
      //   backgroundColor: 'red', // Some CSS
      //   // color: 'red', // Some CSS
      // },
      // outlinedPrimary: { // Name of the rule
      //     backgroundColor: 'red', // Some CSS
      //     // color: 'red', // Some CSS
      // },
    },
  },
});

export default createTheme(myTheme);
