import { makeStyles } from '@material-ui/core/styles';
const useStyles = makeStyles(theme => ({
  pageTitle: {
    [theme.breakpoints.down('sm')]: {
      marginTop: '1.5rem',
    },
    paddingLeft: '1rem',
    fontSize: '2.5rem',
    color: '#fff',
    alignItems: 'center',
    display: 'flex',
  },
  separater: {
    borderBottomWidth: '1px',
    borderBottomColor: '#A4A4A4',
    borderBottomStyle: 'solid',
    margin: '1.5rem 0',
  },
  separater2: {
    borderBottomWidth: '1px',
    borderBottomColor: '#A4A4A4',
    borderBottomStyle: 'solid',
    margin: '1.0rem 0',
  },
  faqTitle: {
    fontSize: '1.7rem',
    marginBottom: '0.5rem',
    color: '#fff',
    fontWeight: '400',
    display: 'flex',
    justifyContent: 'space-between;',
    alignItems: 'center',
    cursor: 'pointer',
  },
  openedFaqTitle: {
    fontSize: '1.7rem',
    marginBottom: '0.5rem',
    color: '#D1FE75',
    fontWeight: '400',
    display: 'flex',
    justifyContent: 'space-between;',
    alignItems: 'center',
    cursor: 'pointer',
  },
  searchFrame: {
    position: 'relative',
  },
  searchInput: {
    margin: '1rem auto',
    width: '100%',
    backgroundColor: '#fff',
    paddingLeft: '2rem',
  },
  faqsBlock: {
    width: '100%',
  },
  qnaTitle: {
    marginLeft: '2rem',
    marginBottom: '1rem',
    fontWeight: '400',
    color: '#eeeeee',
    wordSpacing: '0.2rem',
  },
  qnaBlock: {
    color: 'white',
    marginBottom: '1rem',
  },
  closedDiv: {
    maxHeight: '0rem',
    overflow: 'hidden',
    transition: 'all 0.5s cubic-bezier(0, 1, 0, 1)',
    transitionDuration: '0.5s',
  },
  openedDiv: {
    maxHeight: '80rem',
    overflow: 'hidden',
    transition: 'all 1s ease-in-out',
    transitionDuration: '0.5s',
  },
  answerBlock: {
    marginLeft: '3rem',
    marginBottom: '1rem',
    fontWeight: '300',
  },
  answer: {
    listStyle: 'none',
    wordSpacing: '0.05rem',
    letterSpacing: '0.01rem',
    lineHeight: '1.2rem',
    color: '#eeeeee',
  },
  tooltipImg: {
    marginLeft: '0.5rem',
    width: '1.3rem',
  },
  glass: {
    position: 'absolute',
    left: '1rem',
    top: '50%',
    width: '1.2rem',
    height: '1.2rem',
    transform: 'translate(0, -0.6rem)',
  },
}));

export { useStyles };
