import React, { useEffect, useState, useRef, useCallback } from 'react';
import { useStyles } from './Waveform.styles.js';

import WaveSurfer from 'wavesurfer.js';
import { IconButton, Popover, Slider } from '@mui/material';
import PlayArrowRoundedIcon from '@mui/icons-material/PlayArrowRounded';
import StopRoundedIcon from '@mui/icons-material/StopRounded';
import VolumeUpRoundedIcon from '@mui/icons-material/VolumeUpRounded';
import VolumeDownRoundedIcon from '@mui/icons-material/VolumeDownRounded';
import VolumeMuteRoundedIcon from '@mui/icons-material/VolumeMuteRounded';

export default function Waveform({ tracks, pcm, isToggled }) {
  const classes = useStyles();
  const waveformRef = useRef(null);
  const wavesurfer = useRef(null);
  const [playing, setPlaying] = useState(false);
  const [volume, setVolume] = useState(0.8);
  const [anchorEl, setAnchorEl] = useState(null);
  const [isReady, setIsReady] = useState(false);

  const formWaveSurferOptions = ref => ({
    container: ref,
    waveColor: '#818181',
    progressColor: '#fff',
    cursorColor: 'transparent',
    backend: 'MediaElement',
    barWidth: 3,
    barRadius: 3,
    height: 35,
    normalize: true,
  });

  const createWaveSurfer = useCallback(() => {
    const options = formWaveSurferOptions(waveformRef.current);
    wavesurfer.current = WaveSurfer.create(options);

    const loadTrack = index => {
      const track = tracks[index];
      wavesurfer.current.load(track, pcm.data);
    };

    loadTrack(0);

    wavesurfer.current.on('ready', function () {
      wavesurfer.current.setVolume(volume);
      setIsReady(true);
    });

    wavesurfer.current.on('finish', function () {
      setPlaying(false);
    });
  }, [tracks]);

  useEffect(() => {
    createWaveSurfer();

    return () => {
      if (wavesurfer.current) {
        wavesurfer.current.destroy();
      }
    };
  }, [createWaveSurfer]);

  useEffect(() => {
    const ws = wavesurfer.current;

    const newIndex = isToggled ? 1 : 0;
    const currentTime = ws.getCurrentTime();
    const duration = ws.getDuration();

    const track = tracks[newIndex];
    ws.load(track, pcm.data);

    ws.on('ready', function () {
      if (duration > 0) {
        const seekPosition = currentTime / duration;
        if (seekPosition >= 0 && seekPosition <= 1) {
          ws.seekTo(seekPosition);
        } else {
          ws.seekTo(0);
        }
      } else {
        ws.seekTo(0);
      }
      ws.setVolume(volume);
    });

    if (playing) {
      ws.play();
    }
  }, [isToggled, isReady]);

  const handlePlayPause = useCallback(() => {
    const ws = wavesurfer.current;
    if (!ws) return;

    if (ws.isPlaying()) {
      ws.pause();
      setPlaying(false);
    } else {
      ws.play();
      setPlaying(true);
    }
  }, []);

  const handleVolumeChange = e => {
    const { target } = e;
    const newVolume = +target.value;

    setVolume(newVolume);
    if (wavesurfer.current) {
      wavesurfer.current.setVolume(newVolume);
    }
  };

  useEffect(() => {
    const ws = wavesurfer.current;
    if (ws) {
      ws.setOptions({
        waveColor: isToggled ? '#6e8053' : '#818181',
        progressColor: isToggled ? '#CAFF73' : '#fff',
      });
    }
  }, [isToggled]);

  const getVolumeIcon = () => {
    if (volume === 0) return <VolumeMuteRoundedIcon />;
    if (volume < 0.5) return <VolumeDownRoundedIcon />;
    return <VolumeUpRoundedIcon />;
  };

  const open = Boolean(anchorEl);
  const id = open ? 'volume-popover' : undefined;

  return (
    <div className={classes.waveformContainer}>
      <IconButton
        style={isToggled ? { color: '#CAFF73' } : { color: '#fff' }}
        onClick={handlePlayPause}
        disabled={!isReady}
      >
        {playing ? <StopRoundedIcon /> : <PlayArrowRoundedIcon />}
      </IconButton>
      <div className={classes.waveform} ref={waveformRef} />
      {/* <IconButton
        style={isToggled ? { color: '#CAFF73' } : { color: '#fff' }}
        onClick={(event) => setAnchorEl(event.currentTarget)}
        disabled={!isReady}
      >
        {getVolumeIcon()}
      </IconButton> */}
      <Popover
        className={classes.volumePopover}
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={() => setAnchorEl(null)}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: +175,
          horizontal: 'center',
        }}
      >
        <div className={classes.volumeSliderContainer}>
          <Slider
            className={classes.volumeSlider}
            orientation="vertical"
            value={volume}
            onChange={handleVolumeChange}
            min={0}
            max={1}
            step={0.1}
          />
        </div>
      </Popover>
    </div>
  );
}
