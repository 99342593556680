import * as React from 'react';
import { styled } from '@mui/material/styles';
import Tooltip, { tooltipClasses } from '@mui/material/Tooltip';

const HtmlInfoIcon = styled(({ className, ...props }) => (
  <Tooltip
    placement="top"
    enterTouchDelay={0}
    leaveTouchDelay={300000}
    {...props}
    arrow
    classes={{ popper: className }}
  />
))(({ theme }) => ({
  [`& .${tooltipClasses.arrow}`]: {
    color: '#F1FFD3',
  },
  [`& .${tooltipClasses.tooltip}`]: {
    [theme.breakpoints.down('xs')]: {
      maxWidth: '100%',
    },
    backgroundColor: '#F1FFD3',
    color: 'rgba(0, 0, 0, 0.87)',
    maxWidth: '50rem',
    fontSize: theme.typography.pxToRem(12),
    border: '1px solid #dadde9',
  },
}));

export default HtmlInfoIcon;
