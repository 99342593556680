import React, { useEffect, useState } from 'react';
import Modal from '@material-ui/core/Modal';
import { useTranslation } from 'react-i18next';
import { useStyles } from './ConfirmationModal.styles.js';
import CircularProgress from '@mui/material/CircularProgress';

export default function DownloadModal(props) {
  const { t } = useTranslation();
  const classes = useStyles();
  const [fontStyle, setFontStyle] = useState('TheJamsil');

  useEffect(() => {
    const defaultLanguage = localStorage.getItem('selectedLanguage') || navigator.language.split('-')[0];
    const defaultFont = defaultLanguage === 'ja' ? 'MPlus1' : 'TheJamsil';
    setFontStyle(defaultFont);
  }, []);

  const onClick = res => {
    props.onConfirmation(res);
  };
  return (
    <Modal className={fontStyle} open={!!props.open} onClose={() => props.onConfirmationModalClose()}>
      <div className={classes.container}>
        {!props.loading && (
          <>
            <div className={classes.message}>{props.message}</div>
            <div className={classes.buttonContainer}>
              <div className={classes.yesButton} onClick={() => onClick(true)}>
                {t('modal.confirmationYes')}
              </div>
              <div className={classes.noButton} onClick={() => onClick(false)}>
                {t('modal.confirmationNo')}
              </div>
            </div>
          </>
        )}
        {props.loading && <CircularProgress className={classes.loading} />}
      </div>
    </Modal>
  );
}
