import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(() => ({
  messageContainer: {
    backgroundColor: '#191919',
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: '300px',
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column',
    padding: '2rem 0',
    wordBreak: 'keep-all!important',
    borderRadius: '1.5rem',
    '&:active': { outline: 'none' },
    '&:focus': { outline: 'none' },
  },
  sorisoriAIImg: {
    width: '75%',
    marginBottom: '1.5rem',
  },
  messageOneContainer: {
    marginBottom: '1.5rem',
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column',
  },
  messageOne: {
    fontSize: '1rem',
    fontWeight: '300',
    color: '#c4c4c4',
    marginBottom: '0.3rem',
  },
  button: {
    backgroundColor: '#CAFF73',
    padding: '0.8rem 1.5rem',
    fontSize: '1rem',
    borderRadius: '2rem',
    cursor: 'pointer',
  },
}));

export { useStyles };
