import _ from 'lodash';
import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { appActions } from '../../actions/app.actions.js';
import { userActions } from '../../actions/user.actions.js';
import { useTranslation } from 'react-i18next';

import HtmlTooltip from '../HtmlTooltip';
import tooltipImg from '../../img/tooltip.png';
import { useDropzone } from 'react-dropzone';
import {
  Grid,
  Button,
  OutlinedInput,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  Avatar,
  IconButton,
  CircularProgress,
  ButtonGroup,
} from '@material-ui/core';
import { AudiotrackSharp as AudioIcon, Delete as DeleteIcon } from '@material-ui/icons';

import { dragAndDropStyle } from './shared.styles';
import { useStyles } from './TrainVoicePage.styles';
import { getFilesDuration, getTTSTrainingDuration } from '../../utils/page.utils';
import {
  fetchSubscriptionInfo,
  checkTTSTrainingCompletion,
  uploadTTSTraining,
  uploadModelMethodOne,
  uploadErrorMessage,
} from '../../services/page.services';
import { checkValidLoginStatus } from '../../utils/user.utils';

import { FormControl, FormControlLabel, Radio, RadioGroup } from '@mui/material';
import FreeTrainingMessageModal from '../layout/FreeTrainingMessageModal.js';

function TTSTrainVoicePage() {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const user = useSelector(state => state.user);
  const userId = user?.id;
  const isAdmin = user?.subscription?.type === 'ADMIN';
  const isCustom = user?.subscription?.type === 'CUSTOM';

  const [uploadType, setUploadType] = useState('train');
  const [loading, setLoading] = useState(false);
  const [files, setFiles] = useState([]);
  const [externalFile, setExternalFile] = useState({});
  const [label, setLabel] = useState();
  const [duration, setDuration] = useState(0);
  const [trainingRemainingCount, setTrainingRemainingcount] = useState(0);
  const [trainingInProgress, setTrainingInProgress] = useState(false);
  const [openFreeTrainingModal, setOpenFreeTrainingModal] = useState(false);

  const [needMdx, setNeedMdx] = useState(true);

  useEffect(() => {
    if (userId) (async () => initPage())();
  }, [userId]);

  const trainingDuration = getTTSTrainingDuration(user.subscription?.type, user.subscription?.status === 'past_due');

  const checkTrainingInProgress = async () => {
    const trainings = await checkTTSTrainingCompletion();
    const inProgress = _.some(trainings, { status: 'PENDING' });
    setTrainingInProgress(inProgress);
  };

  const checkRemainingCount = async () => {
    let subscriptionInfo = await fetchSubscriptionInfo();
    dispatch(userActions.updateSubscription(subscriptionInfo));
    setTrainingRemainingcount(subscriptionInfo?.ttsTrainingRemaining || 0);
  };

  const initPage = async (isOnSubmit = false) => {
    try {
      if (!isAdmin && !isCustom) {
        await checkTrainingInProgress();
      }
      await checkRemainingCount();
    } catch (e) {
      const message = isOnSubmit ? t('modal.pageLoadFailOnSubmit') : t('modal.pageLoadFail');
      dispatch(appActions.openMessageModal(message, true));
    }
    setExternalFile({});
    setFiles([]);
    setLabel();
    setDuration(0);
  };

  const onDropAccepted = async acceptedFiles => {
    if (checkValidLoginStatus(userId, dispatch)) {
      let max = await getFilesDuration(files);
      const newFileDuration = await getFilesDuration(acceptedFiles);
      const newMaxDuration = max + newFileDuration;
      if (newMaxDuration > trainingDuration.max) {
        dispatch(
          appActions.openMessageModal(
            t('ttsTrainingTab.modal.exceedMaxDuration', {
              maxDuration: trainingDuration.max / 60,
              minDuration: trainingDuration.min,
            })
          )
        );
      } else {
        setDuration(max + newFileDuration);
        setFiles(prevState => [...prevState, ...acceptedFiles]);
      }
    }
  };

  const onPathDropAccepted = async acceptedFiles => {
    if (checkValidLoginStatus(userId, dispatch)) {
      setExternalFile({ ...externalFile, path: acceptedFiles[0] });
    }
  };

  const onIndexDropAccepted = async acceptedFiles => {
    if (checkValidLoginStatus(userId, dispatch)) {
      setExternalFile({ ...externalFile, index: acceptedFiles[0] });
    }
  };

  const onDropRejected = async input => {
    if (checkValidLoginStatus(userId, dispatch)) {
      const error = input[0].errors[0];
      if (error.code === 'file-invalid-type') {
        dispatch(appActions.openMessageModal(t('trainingTab.modal.notSupportedFileType')));
      }
      if (error.code === 'size-too-large') {
        dispatch(appActions.openMessageModal(t('ttsTrainingTab.modal.sizeTooLarge')));
      }
      if (error.code === 'too-many-files') {
        dispatch(appActions.openMessageModal(t('ttsTrainingTab.modal.tooManyFiles')));
      }
      if (error.code === 'duplicate-files') {
        dispatch(appActions.openMessageModal(t('ttsTrainingTab.modal.duplicateFiles')));
      }
    }
  };
  const fileValidator = file => {
    if (file.size > 10000000) {
      return {
        code: 'size-too-large',
        message: `file is larger than 10MB`,
      };
    }
    if (files.length) {
      for (var uploadedFile of files) {
        if (
          uploadedFile.name === file.name &&
          uploadedFile.size === file.size &&
          uploadedFile.lastModified.toString() === file.lastModified.toString()
        ) {
          return {
            code: 'duplicate-files',
            message: `duplicate files`,
          };
        }
      }
    }

    return null;
  };

  const trainDropzone = useDropzone({
    accept: {
      'audio/mp3': ['.mp3'],
      'audio/wav': ['.wav'],
      'audio/mpeg-4': ['.m4a'],
      'audio/flac': ['.flac'],
      'audio/ogg': ['.ogg'],
      // "video/mpeg-4":[".mp4"],
      // "video/x-matroska ":[".mkv"],
    },
    onDropAccepted,
    onDropRejected,
    preventDuplicates: true,
    disabled: loading,
    validator: fileValidator,
    maxFiles: 10,
  });

  const handleUpload = async (userId, files, label, subscriptionType, needMdx) => {
    try {
      setLoading(true);
      const uploadError = await uploadTTSTraining(userId, files, label, subscriptionType, needMdx);
      if (!!uploadError) {
        if (uploadError === 'pending') {
          dispatch(appActions.openMessageModal(t('modal.trainingInProgressError'), true));
        } else if (uploadError === 'noCount') {
          dispatch(appActions.openMessageModal(t('modal.noRemainingError'), true));
        } else {
          dispatch(appActions.openMessageModal(t('modal.genericError'), true));
        }
      } else {
        await initPage();
      }
    } catch (e) {
      if (e.message) {
        const codeMessage = `code: ${e.code}, message: ${e.message}, config: ${JSON.stringify(e.config)}`;
        await uploadErrorMessage(codeMessage);
      } else {
        await uploadErrorMessage('no message - ' + JSON.stringify(e));
      }
      dispatch(appActions.openMessageModal(t('modal.fileUploadFail')));
    } finally {
      setLoading(false);
    }
  };

  const handleSubmit = async e => {
    if (checkValidLoginStatus(userId, dispatch)) {
      if (user.subscription?.type === 'FREE' && trainingRemainingCount === 0) {
        setOpenFreeTrainingModal(true);
        return;
      }
      if (!label || files.length === 0 || trainingRemainingCount <= 0 || trainingInProgress) {
        return;
      }
      const durationInMin = Math.ceil(duration);
      if (durationInMin < trainingDuration.min) {
        dispatch(
          appActions.openMessageModal(
            t('ttsTrainingTab.modal.minDuration', {
              minDuration: trainingDuration.min,
            })
          )
        );
        return;
      }
      await handleUpload(userId, files, label, user.subscription.type, needMdx);
    }
  };

  const handleButtonClick = uploadType => {
    setUploadType(uploadType);
    setNeedMdx(true);
  };

  const classes = useStyles();
  return (
    <div>
      <FreeTrainingMessageModal open={openFreeTrainingModal} setOpenFreeTrainingModal={setOpenFreeTrainingModal} />
      <div className={classes.pageTitle}>{t('ttsTrainingTab.title')}</div>
      <div className={classes.separater} />
      <Grid container spacing={3}>
        <Grid item xs={12} sm={12} md={12}>
          <div className={classes.stepHeader}>
            {t('trainingTab.stepOne.title')}
            <HtmlTooltip
              title={
                <div className={classes.tooltipContainer}>
                  <div className={classes.tooltipHeader}>{t('trainingTab.stepOne.tooltip.titleOne')}</div>
                  <div className={classes.tooltipTextContainer}>
                    <div className={`${classes.tooltipText} ${classes.tooltipTextNumber}`}>1.</div>
                    <div className={classes.tooltipText}>{t('ttsTrainingTab.stepOne.tooltip.contentsOne.0')}</div>
                  </div>
                  <div className={classes.tooltipTextContainer}>
                    <div className={`${classes.tooltipText} ${classes.tooltipTextNumber}`}>2.</div>
                    <div className={classes.tooltipText}>{t('ttsTrainingTab.stepOne.tooltip.contentsOne.1')}</div>
                  </div>
                  <div className={classes.tooltipTextContainer}>
                    <div className={`${classes.tooltipText} ${classes.tooltipTextNumber}`}>3.</div>
                    <div className={classes.tooltipText}>{t('ttsTrainingTab.stepOne.tooltip.contentsOne.2')}</div>
                  </div>
                  <div className={classes.tooltipTextContainer}>
                    <div className={`${classes.tooltipText} ${classes.tooltipTextNumber}`}>4.</div>
                    <div className={classes.tooltipText}>{t('ttsTrainingTab.stepOne.tooltip.contentsOne.3')}</div>
                  </div>
                  <div className={classes.tooltipTextContainer}>
                    <div className={`${classes.tooltipText} ${classes.tooltipTextNumber}`}>5.</div>
                    <div className={classes.tooltipText}>{t('ttsTrainingTab.stepOne.tooltip.contentsOne.4')}</div>
                  </div>
                </div>
              }
            >
              <img className={classes.tooltipImg} src={tooltipImg} alt="tooltip-img" />
            </HtmlTooltip>
          </div>
          <div className={classes.stepSubHeader}>
            <div className={classes.stepSubHeader}>{t('trainingTab.stepOne.trainingSubTitles.0')}</div>
          </div>
          <div className={classes.dragAndDropContainer}>
            <div {...trainDropzone.getRootProps({ style: dragAndDropStyle })}>
              <input {...trainDropzone.getInputProps()} />
              <div className={classes.dragAndDrop}>
                <div className={classes.dragAndDropText}>{t('trainingTab.stepOne.dragAndDropText')}</div>
                <div className={classes.dragAndDropText}>{t('ttsTrainingTab.stepOne.sizeLimitText')}</div>
                <div className={classes.dragAndDropText}>
                  {t('ttsTrainingTab.stepOne.dragAndDropDuration', {
                    maxDuration: trainingDuration.max / 60,
                    minDuration: trainingDuration.min,
                  }) +
                    ', ' +
                    t('ttsTrainingTab.stepOne.numberLimitText')}
                </div>

                <div className={classes.dragAndDropButton}>{t('trainingTab.stepOne.dragAndDropButton')}</div>
              </div>
            </div>

            <div>
              <List className={classes.dragAndDropList}>
                {files.map((file, index) => (
                  <ListItem key={index} className={classes.listItem}>
                    <ListItemAvatar className={classes.listItemAvatar}>
                      <Avatar className={classes.avatar}>
                        <AudioIcon className={classes.audioIcon} />
                      </Avatar>
                    </ListItemAvatar>
                    <ListItemText className={classes.listItemText} primary={file.name} />
                    <IconButton
                      onClick={async () => {
                        const newFileList = files.filter((_file, i) => i !== index);
                        setFiles(() => newFileList);
                        setDuration(await getFilesDuration(newFileList));
                      }}
                      edge="end"
                      aria-label="delete"
                    >
                      <DeleteIcon style={{ color: '#fff' }} />
                    </IconButton>
                  </ListItem>
                ))}
                <ListItem key={files.length} className={classes.durationText} disableGutters>
                  {t('trainingTab.stepOne.durationText')} : {Math.floor(duration / 60)}{' '}
                  {t('trainingTab.stepOne.durationUnit')} {Math.ceil(duration % 60)}{' '}
                  {t('ttsTrainingTab.stepOne.second')}
                </ListItem>
              </List>
            </div>
          </div>
        </Grid>

        <Grid item xs={12} sm={12} md={12}>
          <div className={`${classes.separater} ${classes.noMargin}`} />
        </Grid>
        <Grid item xs={12} sm={12} md={12}>
          <div className={classes.stepHeader}>{t('trainingTab.stepTwo.title')}</div>
          <div className={classes.stepSubHeader}>{t('trainingTab.stepTwo.subTitles.0')}</div>
        </Grid>
        <Grid item xs={12} sm={12} md={6}>
          <OutlinedInput
            onChange={e => {
              if (checkValidLoginStatus(userId, dispatch)) {
                setLabel(e.target.value);
              }
            }}
            className={classes.labelInput}
            disabled={loading}
          />
        </Grid>
        <Grid className={classes.buttonGrid} item xs={12} sm={12} md={12}>
          {!loading && (
            <div className={classes.buttonContainer}>
              <div className={classes.button} onClick={handleSubmit}>
                {trainingInProgress && userId
                  ? t('trainingTab.submit.inProgress')
                  : userId && user.subscription?.type !== 'FREE' && trainingRemainingCount <= 0
                    ? t('trainingTab.submit.noRemaining')
                    : t('trainingTab.submit.start')}
              </div>
              <HtmlTooltip
                title={
                  <div className={classes.tooltipContainer}>
                    <div className={classes.tooltipTextContainer}>
                      <div className={`${classes.tooltipText} ${classes.tooltipTextBullet}`}>&#x2022;</div>
                      <div className={classes.tooltipText}>{t('ttsTrainingTab.submit.tooltip.contents.0')}</div>
                    </div>
                    <div className={classes.tooltipTextContainer}>
                      <div className={`${classes.tooltipText} ${classes.tooltipTextBullet}`}>&#x2022;</div>
                      <div className={classes.tooltipText}>{t('ttsTrainingTab.submit.tooltip.contents.1')}</div>
                    </div>
                    <div className={classes.tooltipTextContainer}>
                      <div className={`${classes.tooltipText} ${classes.tooltipTextBullet}`}>&#x2022;</div>
                      <div className={classes.tooltipText}>{t('ttsTrainingTab.submit.tooltip.contents.2')}</div>
                    </div>
                  </div>
                }
              >
                <img className={classes.tooltipImg} src={tooltipImg} alt="tooltip-img" />
              </HtmlTooltip>
              {user && trainingRemainingCount > 0 && (
                <div className={classes.buttonRemainingText}>
                  {t('trainingTab.submit.remainingCountText')} : {trainingRemainingCount}
                </div>
              )}
            </div>
          )}
          {loading && (
            <div className={classes.laodingContainer}>
              <CircularProgress size="1.4rem" />
              <span className={classes.laodingText}>{t('trainingTab.submit.uploading')}</span>
            </div>
          )}
        </Grid>
      </Grid>
    </div>
  );
}

export default TTSTrainVoicePage;
